import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Iconify } from 'src/components/iconify';
import { CloseIcon } from 'yet-another-react-lightbox';
import { RootState, store } from 'src/store/store';
import { useSelector } from 'react-redux';
import { GetMachineLearningProjectV2QueryVariables, MachineLearningClassUpdate, MachineLearningModelType, MachineLearningProject, OwnerType } from 'src/API';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { updateMachineLearningProjectWithoutStore } from 'src/store/machineLearningProjects/updateMachineLearningProject';
import NavBarButton from 'src/ui/navBar/NavBarButton';
import { truncateText } from 'src/sections/codelab/utils';
import { updateMachineLearningProject } from 'src/store/machineLearningProjects/machineLearningProjectsSlice';

// ----------------------------------------------------------------------

type MachineLearningEditProjectButtonProps = {
    projectToUpdate: MachineLearningProject
}

export default function MachineLearningEditProjectButton({
    projectToUpdate
}: MachineLearningEditProjectButtonProps) {
    const [open, updateOpen] = useState(false);

    const {
        id,
        projectName,
        modelType,
    } = projectToUpdate
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false)

    const [updatedProjectName, setUpdatedProjectName] = useState<string>(projectName)
    const projectNameMaxCharacters = 50
    useEffect(() => {
        setIsConfirmed(false)
        setUpdatedProjectName(projectName)
    }, [open, projectName])

    const onClose = () => updateOpen(false)
    return (
        <>
            <NavBarButton onClick={() => updateOpen(true)} icon='edit' text='Edit Model' />

            <Dialog fullWidth maxWidth="sm" open={open} onClose={(_, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose()
                }
            }}>
                <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>
                    <Box alignItems="center">
                        <Typography variant="h6" gutterBottom>
                            Create Machine Learning Model
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent>
                    <Box mb={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Model Name
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={updatedProjectName ?? ''}
                            autoFocus
                            placeholder="Give your model a name"
                            error={updatedProjectName.length > projectNameMaxCharacters}
                            helperText={'Model name must be less than 50 characters'}
                            onChange={(event) => {
                                const v = event.target.value
                                setUpdatedProjectName(v)
                            }}
                        />
                    </Box>

                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Model Type
                        </Typography>
                        <RadioGroup
                            row
                            sx={{
                                ml: 1
                            }}
                            value={modelType}
                        >
                            {
                                (Object.values(MachineLearningModelType))
                                    .map(k => (
                                        <FormControlLabel
                                            key={k}
                                            value={k}
                                            control={<Radio />}
                                            disabled
                                            label={k.charAt(0).toUpperCase() + k.slice(1).toLowerCase()} />
                                    ))
                            }
                        </RadioGroup>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>

                    <LoadingButton
                        loading={isConfirmed}
                        disabled={isConfirmed}
                        color="error"
                        type="submit"
                        variant="contained"
                        onClick={async () => {
                            setIsConfirmed(true)
                            await updateMachineLearningProjectWithoutStore({
                                id,
                                updatedProjectName,
                                updatedClasses: projectToUpdate.classes.map(c => ({
                                    id: c.id,
                                    name: c.name,
                                    isDisabled: c.isDisabled
                                } as MachineLearningClassUpdate)),
                                shouldGenerateFileUploadURL: false
                            })
                            updateOpen(false)
                        }}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
