/**
 * Truncate the text to the specified maximum length and append an ellipsis if needed.
 * @param text The text to be truncated.
 * @param maxLength The maximum allowed length of the text.
 * @returns The truncated text with ellipsis if it exceeds the maxLength.
 */
export function truncateText(text: string, maxLength: number): string {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength - 3) + '...';
}