import { useNavigate } from 'react-router-dom'
import NavBarButton from '../../../ui/navBar/NavBarButton'
import { paths } from 'src/routes/paths';


// TODO change to route
// TODO remove modal. render on the same screen
function BrowseProjectsListButton() {
    const navigate = useNavigate()
    const browseProjectsButtonOnClick = () => {
        navigate(paths.codelab.allProjects)
    }

    return (
        <NavBarButton onClick={browseProjectsButtonOnClick} text={"My Projects"} icon={'allProjects'} />
    );
}

export default BrowseProjectsListButton