import NavBarButton from '../../../ui/navBar/NavBarButton'
import CreateOrEditProjectModal from './CreateOrEditProjectModal'
import { useState } from 'react'

function CreateNewProjectButton() {
    const [projectImage, setProjectImage] = useState<File | undefined>(undefined)
    const [projectImageURL, setProjectImageURL] = useState<string | undefined>(undefined)
    const [projectNameValidated, setProjectNameValidated] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectNameTyped, setProjectNameTyped] = useState(false);
    const [hasProjectImageChanged, setHasProjectImageChanged] = useState<boolean>(false)
    const { modal, showModal } = CreateOrEditProjectModal({
        isCreate: true,
        projectImage,
        setProjectImage: (image) => { setProjectImage(image) },
        projectImageURL,
        setProjectImageURL: (url) => { setProjectImageURL(url) },
        projectNameValidated,
        setProjectNameValidated: (projectName) => { setProjectNameValidated(projectName) },
        projectName,
        setProjectName: (projectName) => { setProjectName(projectName) },
        projectNameTyped,
        setProjectNameTyped: (projectNameTyped) => { setProjectNameTyped(projectNameTyped) },
        hasProjectImageChanged,
        setHasProjectImageChanged: (changed) => { setHasProjectImageChanged(changed) }
    })
    return (
        <>
            <NavBarButton onClick={() => showModal()} text={"New Project"} icon={"create"}></NavBarButton>
            {modal()}
        </>
    );
}

export default CreateNewProjectButton