import { push } from '@lagunovsky/redux-react-router'
import { createSlice, PayloadAction, createAsyncThunk, createListenerMiddleware } from '@reduxjs/toolkit'
import { BlocklyProject, ListBlocklyProjectsQuery, FileCategory, GetBlocklyProjectByIDQuery, GetBlocklyProjectByIDQueryVariables, CreateBlocklyProjectMutationVariables, CreateBlocklyProjectCustomMutation, CreateBlocklyProjectCustomMutationVariables, FileTypeInput, FileType, GetBlocklyProjectsByOwnerQuery, GetBlocklyProjectsByOwnerQueryVariables, ConvertTfjsToTfLiteMutation, ConvertTfjsToTfLiteMutationVariables } from "../../API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import { CreateBlocklyProjectInput, CreateBlocklyProjectMutation, UpdateBlocklyProjectInput, UpdateBlocklyProjectMutation, GetBlocklyProjectQuery, GetBlocklyProjectQueryVariables, DeleteBlocklyProjectInput, DeleteBlocklyProjectMutation } from '../../API';
import * as mutations from '../../graphql/mutations';
import { RootState, AppDispatch, store } from '../store'
import { downloadBlocklyProjectFileFromLocalOrS3, removeBlocklyProjectFileFromS3, S3FileDownloadResult, S3FileDownloadErrorType, uploadBlocklyProjectFileToS3 } from '../../fileStorage/s3Util'
import * as ProjectFiles from './projectFiles'
import { DateTime } from 'luxon';
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { paths } from 'src/routes/paths';
import { ReducerStatus, axiosClientWithLongTimeout, graphQLClient } from '../util';
import { routeReplaceOrPushPath } from '../router/routerCustomSlice';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCustomAPIBaseURL } from 'src/config-global';
import awsmobile from 'src/aws-exports';
import { DownloadStatus } from 'src/fileStorage/fileManager';

export interface BlocklyProjectFileWrapper {
    name: string,
    category: FileCategory,
    usedInProject: boolean | undefined, // updated by application / blockly workspace once its loaded
    downloadStatus: DownloadStatus,
    lastUpdatedTimestamp: string,
    identityId: string
}

type BlocklyWorkspace = {
    id: string,
    workspaceJson: object,
    projectName: string,
    updatedAt: string,
    hasUnsavedChanges: boolean,
    files: BlocklyProjectFileWrapper[],
    machineLearningProjectIDs: string[]
};

interface BlocklyProjectsState {
    my_projects: BlocklyProject[], // TODO do not include workspace
    fetchProjectsStatus?: ReducerStatus,
    currentWorkspace: BlocklyWorkspace | null
}

// TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
const initialState: BlocklyProjectsState = {
    my_projects: [],
    currentWorkspace: null
}

// TODO check out this
// async function postRequest(): Promise<{}> {
//     const currentSession = await fetchAuthSession();
//     const token = currentSession.tokens?.accessToken?.toString();
//     console.log(token);

//     const response = await fetch(getCustomAPIBaseURL(), {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//         },
//     });

//     console.log(response);
//     if (!response.ok) {
//         throw new Error('Network response was not ok');
//     }

//     return response.json();
// };

export const fetchProjects = createAsyncThunk<
    BlocklyProject[], // output
    void, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/fetchProjects', async (_, { dispatch, getState }) => {
    let shouldFetch = true
    let nextToken = null
    let projects: BlocklyProject[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: GetBlocklyProjectsByOwnerQueryVariables = {
            nextToken,
            owner: getState().authentication.loggedInUser!!.userName,
            limit: 20
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<GetBlocklyProjectsByOwnerQuery> = await graphQLClient.graphql<GraphQLQuery<GetBlocklyProjectsByOwnerQuery>>({
            query: queries.getBlocklyProjectsByOwner,
            variables
        });
        nextToken = response.data?.getBlocklyProjectsByOwner?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        projects = projects.concat(response.data!!.getBlocklyProjectsByOwner!!.items!! as BlocklyProject[])
    }
    /* eslint-enable no-await-in-loop */

    // sort by update timestamp desc
    projects = (projects.sort((left, right) => right!!.updatedAt!!.localeCompare(left!!.updatedAt!!)) ?? []) as BlocklyProject[]

    return projects
})

export type UploadFileToLocalAndS3Props = {
    file: File,
    category: FileCategory,
    projectID: string,
    lastUpdatedTimestamp: string
}

export const uploadFileToLocalAndS3 = createAsyncThunk<
    void,
    UploadFileToLocalAndS3Props, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/uploadFileToLocalAndS3', async ({ file, category, projectID, lastUpdatedTimestamp }: UploadFileToLocalAndS3Props, { dispatch, getState }) => {
    dispatch(addFileLocally({
        file,
        category: FileCategory.PROJECT_PROFILE_IMAGE,
        lastUpdatedTimestamp,
        identityId: getState().authentication.loggedInUser!!.cognitoIdentityID ?? ''
    }))

    uploadBlocklyProjectFileToS3({
        validatedFile: file,
        fileCategory: category,
        projectID,
        completeCallback: () => {
            // TODO?
        },
        progressCallback: () => { },
        errorCallback: () => {
            // TODO handle error
        }
    })
})

export type CreateNewProjectProps = {
    projectName: string,
    projectImage?: File
}

export const createNewProject = createAsyncThunk<
    BlocklyWorkspace,
    CreateNewProjectProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/createNewProject', async ({ projectName, projectImage }: CreateNewProjectProps, { dispatch, getState }) => {
    const lastUpdatedTimestamp = DateTime.now().toMillis().toString()
    const input: CreateBlocklyProjectInput = {
        workspaceJson: JSON.stringify({
            blocks: {
                languageVersion: 0,
                blocks: [
                    {
                        type: 'start_block',
                        id: 'c_/rz-6A/4g4p$hL6_T9',
                        x: 100,
                        y: 100,
                        deletable: false
                    }
                ]
            }
        } as object),
        projectName,
        identityID: getState().authentication.loggedInUser!!.cognitoIdentityID ?? '',
        files: projectImage ? [{ name: projectImage.name, category: FileCategory.PROJECT_PROFILE_IMAGE, lastUpdatedTimestamp, identityId: getState().authentication.loggedInUser!!.cognitoIdentityID }] : [],
        machineLearningProjectIDs: [],
    }

    const response = await graphQLClient.graphql<GraphQLQuery<CreateBlocklyProjectMutation>>({
        query: mutations.createBlocklyProject,
        variables: {
            input
        }
    });
    const workspace = response.data!!.createBlocklyProject!!
    if (projectImage !== undefined) {
        dispatch(uploadFileToLocalAndS3({ file: projectImage, category: FileCategory.PROJECT_PROFILE_IMAGE, projectID: workspace.id, lastUpdatedTimestamp }))
    }

    return {
        id: workspace.id,
        workspaceJson: JSON.parse(workspace.workspaceJson) as object,
        projectName: workspace.projectName,
        updatedAt: workspace.updatedAt,
        hasUnsavedChanges: false,
        files: input.files.map(f => { return { name: f.name, category: f.category, usedInProject: true, downloadStatus: DownloadStatus.COMPLETED, lastUpdatedTimestamp, identityId: f.identityId ?? getState().authentication.loggedInUser!!.cognitoIdentityID ?? '' } }),
        machineLearningProjectIDs: input.machineLearningProjectIDs ?? []
    };
})

export interface UpdateProjectProps {
    projectName: string,
    projectImage?: File // undefined means no change
}

export const updateProject = createAsyncThunk<
    {
        updatedProject: BlocklyProject,
        updatedCurrentWorkspace: BlocklyWorkspace
    },
    UpdateProjectProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/updateProject', async ({ projectName, projectImage }, { dispatch, getState }) => {
    let input: UpdateBlocklyProjectInput = {
        id: getState().blocklyProjects.currentWorkspace!!.id,
        projectName
    }

    let files = getState().blocklyProjects.currentWorkspace!!.files
    const existingProjectImageName = files.find(f => f.category === FileCategory.PROJECT_PROFILE_IMAGE)?.name
    const shouldUpdateProjectImage = projectImage !== undefined
    const lastUpdatedTimestamp = DateTime.now().toMillis().toString()
    if (shouldUpdateProjectImage) {
        files = files.filter(f => f.category !== FileCategory.PROJECT_PROFILE_IMAGE)
        files.push({
            name: projectImage.name,
            category: FileCategory.PROJECT_PROFILE_IMAGE,
            usedInProject: true,
            downloadStatus: DownloadStatus.COMPLETED,
            lastUpdatedTimestamp,
            identityId: getState().authentication.loggedInUser!!.cognitoIdentityID ?? ''
        })
        input = {
            ...input,
            files: files.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp, identityId: f.identityId } })
        }
    }

    const project = await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectMutation>>({
        query: mutations.updateBlocklyProject,
        variables: {
            input
        }
    }).then(d => d.data!!.updateBlocklyProject!!)

    if (shouldUpdateProjectImage) {
        if (existingProjectImageName !== undefined && existingProjectImageName !== projectImage.name) {
            dispatch(removeFileFromWorkspaceAndS3({
                fileName: existingProjectImageName,
                fileCategory: FileCategory.PROJECT_PROFILE_IMAGE
            }))
        }
        dispatch(uploadFileToLocalAndS3({
            projectID: getState().blocklyProjects.currentWorkspace!!.id,
            file: projectImage,
            category: FileCategory.PROJECT_PROFILE_IMAGE,
            lastUpdatedTimestamp
        }))
    }
    return {
        updatedProject: project,
        updatedCurrentWorkspace: {
            ...getState().blocklyProjects.currentWorkspace!!,
            projectName,
            updatedAt: project.updatedAt,
            files
        }
    };
})

// TODO optimize - do not update when there's no new changes
export const saveProjectWorkspace = createAsyncThunk<
    BlocklyWorkspace, // output
    () => void, // input argument - onSuccess callback function
    {
        state: RootState
    }
>('blocklyProjects/saveProjectWorkspace', async (onSuccess, { getState }) => {
    const currentWorkspace = getState().blocklyProjects.currentWorkspace!!
    // don't save if there's no changes
    if (!currentWorkspace.hasUnsavedChanges) {
        onSuccess()
        return {
            ...currentWorkspace,
            hasUnsavedChanges: false,
            updatedAt: currentWorkspace.updatedAt,
        }
    }
    const input: UpdateBlocklyProjectInput = {
        id: currentWorkspace.id,
        workspaceJson: JSON.stringify(currentWorkspace.workspaceJson),
        machineLearningProjectIDs: currentWorkspace.machineLearningProjectIDs ?? []
    }
    const response = await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectMutation>>({
        query: mutations.updateBlocklyProject,
        variables: {
            input
        }
    });
    const data = response.data?.updateBlocklyProject
    onSuccess()
    return {
        ...currentWorkspace,
        updatedAt: data!!.updatedAt,
        hasUnsavedChanges: false
    }
})

// the file has already been uploaded to S3, this action is just to update the blocklyproject metadata
export const syncFilesToServerBlocklyProject = createAsyncThunk<
    void, // output - files
    void, // input argument - file name
    {
        state: RootState
    }
>('blocklyProjects/syncFilesToServerBlocklyProject', async (_, { getState }) => {
    const currentWorkspace = getState().blocklyProjects.currentWorkspace!!
    const input: UpdateBlocklyProjectInput = {
        id: currentWorkspace.id,
        files: currentWorkspace.files.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp, identityId: f.identityId } })
    }
    await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectMutation>>({
        query: mutations.updateBlocklyProject,
        variables: {
            input
        }
    });
})

export type RemoveFileFromWorkspaceAndS3Props = {
    fileName: string,
    fileCategory: FileCategory
}

export const removeFileFromWorkspaceAndS3 = createAsyncThunk<
    BlocklyProjectFileWrapper[], // output - files
    RemoveFileFromWorkspaceAndS3Props, // input argument - file name
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/removeFileFromWorkspaceAndS3', async ({ fileName, fileCategory }: RemoveFileFromWorkspaceAndS3Props, { getState }) => {
    const projectID = getState().blocklyProjects.currentWorkspace!!.id
    const filesAfterRemoval = getState().blocklyProjects.currentWorkspace!!.files.filter(f => !(f.name === fileName && f.category === fileCategory))

    // update workspace remotely
    const input: UpdateBlocklyProjectInput = {
        id: projectID,
        files: filesAfterRemoval.map(f => { return { name: f.name, category: f.category, lastUpdatedTimestamp: f.lastUpdatedTimestamp } })
    }
    await graphQLClient.graphql<GraphQLQuery<UpdateBlocklyProjectMutation>>({
        query: mutations.updateBlocklyProject,
        variables: {
            input
        }
    });

    await removeBlocklyProjectFileFromS3(fileName, projectID, fileCategory)

    return filesAfterRemoval
})

const getOnFileDownloadError = (dispatch: AppDispatch, fileName: string, fileCategory: FileCategory) => {
    return (result: S3FileDownloadResult) => {
        if (result.errorType === S3FileDownloadErrorType.FILE_DOES_NOT_EXIST) {
            dispatch(removeFileFromWorkspaceAndS3({ fileName, fileCategory }))
        }
        dispatch(updateFileDownloadStatus({ fileName: result.file!!.name, fileCategory: result.category, downloadStatus: DownloadStatus.FAILED }))
    }
}

const getOnFileDownloadSuccess = (dispatch: AppDispatch) => {
    return (result: S3FileDownloadResult) => {
        dispatch(updateFileDownloadStatus({ fileName: result.file!!.name, fileCategory: result.category, downloadStatus: DownloadStatus.COMPLETED }))
    }
}

export type LoadFileFromLocalOrS3Props = {
    fileName: string,
    category: FileCategory
}

export const loadFileFromLocalOrS3 = createAsyncThunk<
    void, // output
    LoadFileFromLocalOrS3Props, // input argument - file name
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/loadFileFromLocalOrS3', async ({ fileName, category }: LoadFileFromLocalOrS3Props, { dispatch, getState }) => {
    try {
        const projectID = getState().blocklyProjects.currentWorkspace!!.id
        // return early for no-op
        if (getState().blocklyProjects.currentWorkspace!!.files.find(f => f.name === fileName && f.category === category && f.downloadStatus !== DownloadStatus.NOT_STARTED)) {
            return
        }
        dispatch(updateFileDownloadStatus(
            { fileName, fileCategory: category, downloadStatus: DownloadStatus.IN_PROGRESS }
        ))
        // TODO ideally we just need to manage S3, and have S3 trigger lambda to update project status
        await downloadBlocklyProjectFileFromLocalOrS3(fileName, category, projectID, getOnFileDownloadError(dispatch, fileName, category), getOnFileDownloadSuccess(dispatch))
    } catch (e) {
        console.error(e)
        throw e
    }
})

export const loadRemainingRequiredFileFromS3 = createAsyncThunk<
    void, // output - files
    FileCategory, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/loadRemainingRequiredFileFromS3', async (fileCategory, { dispatch, getState }) => {
    try {
        const projectID = getState().blocklyProjects.currentWorkspace!!.id
        // TODO don't have to download all images at once
        const filesToDownload: BlocklyProjectFileWrapper[] = getState().blocklyProjects.currentWorkspace!!.files
            .filter(f => f.downloadStatus === DownloadStatus.NOT_STARTED && fileCategory === f.category)
        Promise.all(filesToDownload.map(f =>
            downloadBlocklyProjectFileFromLocalOrS3(f.name, f.category, projectID, getOnFileDownloadError(dispatch, f.name, f.category), getOnFileDownloadSuccess(dispatch)
            )))
    } catch (e) {
        console.error(e)
        throw e
    }
})

const loadBlocklyProjectById = async (projectID: string) => {
    const variables: GetBlocklyProjectByIDQueryVariables = {
        id: projectID
    }
    return graphQLClient.graphql<GraphQLQuery<GetBlocklyProjectByIDQuery>>({
        query: queries.getBlocklyProjectByID,
        variables
    });
}

const mapBlocklyFileToWorkspaceFile = (file: FileType, userIdentityId: string) => {
    return {
        name: file.name,
        category: file.category,
        usedInProject: undefined,
        downloadStatus: DownloadStatus.NOT_STARTED,
        lastUpdatedTimestamp: file.lastUpdatedTimestamp ?? DateTime.now().toMillis().toString(),
        identityId: file.identityId ?? userIdentityId
    }
}

export const selectBlocklyProject = createAsyncThunk<
    {
        projectID: string,
        updateWorkspace: BlocklyWorkspace | undefined,
        blocklyProjectLoadedFromServer: BlocklyProject | undefined,
    }, // output
    {
        projectID: string,
    }, // input argument - blocklyProject id
    {
        dispatch: AppDispatch,
        state: RootState,
    }
>('blocklyProjects/selectBlocklyProject', async ({ projectID }, { dispatch, getState }) => {
    // don't load if already loaded
    if (getState().blocklyProjects.currentWorkspace?.id === projectID) {
        return {
            projectID,
            updateWorkspace: undefined,
            blocklyProjectLoadedFromServer: undefined,
        }
    }

    // use existing from project list
    const existing = getState().blocklyProjects.my_projects.find(p => p.id === projectID)
    if (existing) {
        return {
            projectID,
            updateWorkspace: {
                id: existing.id,
                workspaceJson: JSON.parse(existing.workspaceJson) as object,
                projectName: existing.projectName,
                updatedAt: existing.updatedAt,
                hasUnsavedChanges: false,
                files: existing.files.map(f => mapBlocklyFileToWorkspaceFile(f, getState().authentication.loggedInUser!!.cognitoIdentityID ?? '')),
                machineLearningProjectIDs: existing.machineLearningProjectIDs ?? [],
            },
            blocklyProjectLoadedFromServer: undefined,
        }
    }

    const response = await loadBlocklyProjectById(projectID)
    const blocklyProject = response.data!!.getBlocklyProjectByID!!
    return {
        projectID,
        updateWorkspace: {
            id: blocklyProject.id,
            workspaceJson: JSON.parse(blocklyProject.workspaceJson) as object,
            projectName: blocklyProject.projectName,
            updatedAt: blocklyProject.updatedAt,
            hasUnsavedChanges: false,
            files: blocklyProject.files.map(f => mapBlocklyFileToWorkspaceFile(f, getState().authentication.loggedInUser!!.cognitoIdentityID ?? '')),
            machineLearningProjectIDs: blocklyProject.machineLearningProjectIDs ?? []
        },
        blocklyProjectLoadedFromServer: blocklyProject as BlocklyProject,
    }
})

export const copyBlocklyProjectsByID = createAsyncThunk<
    BlocklyProject | null | undefined, // the new project...
    {
        projectIDToCopy: string, // input argument - projectID
        projectName: string,
        groupID: string | undefined, // 
        onSuccess: () => void
        onFailure: () => void
    }, // input
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/copyBlocklyProjectsByID', async ({ projectIDToCopy, groupID, projectName, onSuccess, onFailure }, { dispatch, getState }) => {
    // TODO make sure my_projects stay in sync with current workspace??? merge some of current workspace data to my_projects maybe?
    const projectToCopy = getState().blocklyProjects.my_projects.find(p => p.id === projectIDToCopy)!!
    const input: CreateBlocklyProjectCustomMutationVariables = {
        groupID,
        workspaceJson: projectToCopy.workspaceJson,
        projectName,
        identityID: projectToCopy.identityID,
        // files: projectToCopy.files.map(f => {
        //     const { __typename, ...fileTypeInput } = f
        //     return fileTypeInput as FileTypeInput
        // }),
        files: [] // We intentionally don't copy over previous files because they will be fetched using the new project id... 
    }
    const response = await graphQLClient.graphql<GraphQLQuery<CreateBlocklyProjectCustomMutation>>({
        query: mutations.createBlocklyProjectCustom,
        variables: {
            ...input
        }
    });

    if ((response.errors?.length ?? 0) > 0) {
        console.error(response.errors)
        onFailure()
    }
    else if (response.data?.createBlocklyProjectCustom != null) {
        onSuccess()
    }
    return response.data?.createBlocklyProjectCustom
})

// TODO convert to batch mutation
// See https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/custom-business-logic/
export const deleteBlocklyProjectsByID = createAsyncThunk<
    BlocklyProject[],
    string, // input argument - projectIDs
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('blocklyProjects/deleteBlocklyProjectsByID', async (projectIDToDelete, { dispatch, getState }) => {
    const input: DeleteBlocklyProjectInput = {
        id: projectIDToDelete
    }
    await graphQLClient.graphql<GraphQLQuery<DeleteBlocklyProjectMutation>>({
        query: mutations.deleteBlocklyProject,
        variables: {
            input
        }
    });
    return getState().blocklyProjects.my_projects.filter(p => p.id !== projectIDToDelete);
})

export type AddFileLocallyPayload = {
    file: File,
    category: FileCategory,
    lastUpdatedTimestamp: string,
    identityId: string
}

export type RemoveFileLocallyPayload = {
    fileName: string,
    category: FileCategory
}

export const blocklyProjectsSlice = createSlice({
    name: 'blocklyProjects',
    initialState,
    reducers: {
        updateBlocklyAssociatedMachineLearningProjects: (state, action: PayloadAction<{ mlProjectIDToAdd: string | undefined, mlProjectIDToRemove: string | undefined }>) => {
            state.currentWorkspace!!.machineLearningProjectIDs = [
                ...state.currentWorkspace!!.machineLearningProjectIDs.filter(x => x !== action.payload.mlProjectIDToRemove),
                action.payload.mlProjectIDToAdd
            ]
                .filter(x => x !== undefined) as string[];
            state.currentWorkspace!!.hasUnsavedChanges = true
        },
        updateFileDownloadStatus: (state, action: PayloadAction<{ fileName: string, fileCategory: FileCategory, downloadStatus: DownloadStatus }>) => {
            const f = state.currentWorkspace!!.files.find(f => f.name === action.payload.fileName && f.category === action.payload.fileCategory)
            if (f) f.downloadStatus = action.payload.downloadStatus
        },
        tryMarkFileAsUsedInProject: (state, action: PayloadAction<string>) => {
            const file = state.currentWorkspace!!.files.find(f => f.name === action.payload)
            if (file) {
                file.usedInProject = true
            }
        },
        updateCurrentProjectWorkspaceJson: (state, action: PayloadAction<object>) => {
            if (JSON.stringify(state.currentWorkspace!!.workspaceJson) !== JSON.stringify(action.payload)) {
                state.currentWorkspace!!.hasUnsavedChanges = true
                state.currentWorkspace!!.workspaceJson = action.payload
            }
        },
        addFileLocally: (state, action: PayloadAction<AddFileLocallyPayload>) => {
            const fileToAdd = action.payload
            const files = state.currentWorkspace!!.files.filter(f => !(f.name === fileToAdd.file.name && f.category === fileToAdd.category))
            ProjectFiles.addProjectFile(fileToAdd.file, fileToAdd.category)
            files.push({
                name: fileToAdd.file.name,
                category: fileToAdd.category,
                usedInProject: false,
                downloadStatus: DownloadStatus.COMPLETED,
                lastUpdatedTimestamp: fileToAdd.lastUpdatedTimestamp,
                identityId: fileToAdd.identityId
            })
            state.currentWorkspace!!.files = files
        },
        removeFileLocally: (state, action: PayloadAction<RemoveFileLocallyPayload>) => {
            const fileToRemove = action.payload
            ProjectFiles.removeProjectFile(fileToRemove.fileName, fileToRemove.category)
            state.currentWorkspace!!.files = state.currentWorkspace!!.files.filter(f => !(f.name === fileToRemove.fileName && f.category === fileToRemove.category))
        },
    },
    extraReducers(builder) {
        // fetch project
        builder.addCase(fetchProjects.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            state.fetchProjectsStatus = 'pending'
        })
        builder.addCase(fetchProjects.fulfilled, (state, action) => {
            state.my_projects = action.payload
            state.fetchProjectsStatus = 'fulfilled'
        })
        builder.addCase(fetchProjects.rejected, (state, action) => {
            state.fetchProjectsStatus = 'rejected'
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load your CodeLab projects')
        })

        // createNewProject
        builder.addCase(createNewProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(createNewProject.fulfilled, (state, action) => {
            state.currentWorkspace = action.payload
            // there is a listener attached to the fulfilled action
            enqueueSuccessSnackbarMessage('Successfully created CodeLab project')
        })
        builder.addCase(createNewProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error creating CodeLab project')
        })

        // updateProject
        builder.addCase(updateProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateProject.fulfilled, (state, action) => {
            state.currentWorkspace = action.payload.updatedCurrentWorkspace
            state.my_projects = [action.payload.updatedProject, ...state.my_projects.filter(p => p.id !== action.payload.updatedProject.id)]
            enqueueSuccessSnackbarMessage('Successfully updated CodeLab project')
        })
        builder.addCase(updateProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error updating CodeLab project')
        })

        // saveCurrentProject
        builder.addCase(saveProjectWorkspace.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(saveProjectWorkspace.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            state.currentWorkspace = action.payload
            enqueueSuccessSnackbarMessage('Successfully saved CodeLab project')
        })
        builder.addCase(saveProjectWorkspace.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Error saving CodeLab project')
        })

        // selectBlocklyProject
        builder.addCase(selectBlocklyProject.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(selectBlocklyProject.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            if (action.payload.updateWorkspace) {
                state.currentWorkspace = action.payload.updateWorkspace
            }
            const { blocklyProjectLoadedFromServer } = action.payload
            if (blocklyProjectLoadedFromServer) {
                state.my_projects = [blocklyProjectLoadedFromServer, ...state.my_projects].sort((left, right) => right!!.updatedAt!!.localeCompare(left!!.updatedAt!!))
            }
        })
        builder.addCase(selectBlocklyProject.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage(`CodeLab project doesn't exist or you don't have permission to access it`)
        })

        // loadFileFromLocalOrS3
        builder.addCase(loadFileFromLocalOrS3.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(loadFileFromLocalOrS3.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(loadFileFromLocalOrS3.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })

        // loadRemainingRequiredFileFromS3
        builder.addCase(loadRemainingRequiredFileFromS3.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(loadRemainingRequiredFileFromS3.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(loadRemainingRequiredFileFromS3.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })

        // removeFileFromWorkspaceAndS3
        builder.addCase(removeFileFromWorkspaceAndS3.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(removeFileFromWorkspaceAndS3.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            state.currentWorkspace!!.files = action.payload
            enqueueSuccessSnackbarMessage('Successfully deleted project file')
        })
        builder.addCase(removeFileFromWorkspaceAndS3.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to delete the project file')
        })

        // deleteBlocklyProjectsByID
        builder.addCase(deleteBlocklyProjectsByID.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(deleteBlocklyProjectsByID.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            state.my_projects = action.payload
            enqueueSuccessSnackbarMessage('Successfully deleted CodeLab project')
        })
        builder.addCase(deleteBlocklyProjectsByID.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to delete CodeLab project')
        })

        // copyBlocklyProjectsByID
        builder.addCase(copyBlocklyProjectsByID.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(copyBlocklyProjectsByID.fulfilled, (state, action) => {
            // TODO add a status state for completion. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            if (action.payload) {
                state.my_projects = [action.payload, ...state.my_projects]
            }
            enqueueSuccessSnackbarMessage('Successfully copied the CodeLab project')
        })
        builder.addCase(copyBlocklyProjectsByID.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to copy CodeLab project')
        })
    }
})

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: createNewProject.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(selectBlocklyProject({ projectID: action.payload.id }))
    },
});

listenerMiddleware.startListening({
    actionCreator: selectBlocklyProject.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.codelab.project(action.payload.projectID)))
    }
})

listenerMiddleware.startListening({
    actionCreator: selectBlocklyProject.rejected,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.page404))
    }
})

export const { updateBlocklyAssociatedMachineLearningProjects, updateCurrentProjectWorkspaceJson, addFileLocally, removeFileLocally, updateFileDownloadStatus, tryMarkFileAsUsedInProject } = blocklyProjectsSlice.actions

export default blocklyProjectsSlice.reducer