import { m } from 'framer-motion';
import { useState, useEffect } from 'react';

import { alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import Logo from '../logo';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

type SplashScreenProps = {
  boxProps?: BoxProps;
  textBelow?: string
}

export default function SplashScreen({ boxProps, textBelow }: SplashScreenProps) {
  const [mounted, setMounted] = useState(false);
  let boxOtherProps
  if (boxProps) {
    const { sx, ...other } = boxProps
    boxOtherProps = other
  }


  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          right: 0,
          width: 1,
          bottom: 0,
          height: 1,
          zIndex: 9998,
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.default',
          ...boxProps?.sx,
        }}
        {...boxOtherProps}
      >
        <>
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <Logo disableLink sx={{ width: 64, height: 64 }} logoType='original' />
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.6, 1, 1, 1.6, 1.6],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              position: 'absolute',
              border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              position: 'absolute',
              border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
            }}
          />
        </>
      </Box>
      {textBelow && <Box
        sx={{
          right: 0,
          width: 1,
          bottom: 0,
          height: 1,
          zIndex: 9998,
          display: 'flex',
          flexDirection: "column",
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          // bgcolor: 'background.default',
          ...boxProps?.sx,
        }}
        {...boxOtherProps}
      >
        <Typography sx={{ mt: '200px' }} variant="subtitle2">{textBelow}</Typography>
      </Box>}
    </>
  );
}
