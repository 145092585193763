function formatDateToYYYYMMDD(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function getLocalDateStringFromString(dateString: string): string {
    const date = new Date(dateString);
    const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return formatDateToYYYYMMDD(localDate) // returns 'YYYY-MM-DD'
}

export function getLocalDateStringFromDate(date: Date): string {
    const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    return formatDateToYYYYMMDD(localDate)  // returns 'YYYY-MM-DD'
}

export function getNextDayString(dateString: string): string {
    // Parse the input date string to a Date object
    const date = new Date(dateString);

    // Add one day to the Date object
    date.setDate(date.getDate() + 1);

    return getLocalDateStringFromDate(date)
}

export function getPreviousDayString(dateString: string): string {
    // Parse the input date string to a Date object
    const date = new Date(dateString);

    // Add one day to the Date object
    date.setDate(date.getDate() - 1);

    return getLocalDateStringFromDate(date)
}