import { push } from "@lagunovsky/redux-react-router";
import { AppDispatch, RootState, store } from "../store";
import { get } from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface InitialState {
}

const initialState: InitialState = {}

interface LocationState {
    pathname: string;
    search: string;
    hash: string;
}

export const routeReplaceOrPushPath = createAsyncThunk<
    void,
    string, // input argument - newURI
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('routerCustom/routeReplaceOrPushPath', async (newURI, { dispatch, getState }) => {
    const pathname = get(getState(), 'router.location.pathname');
    const search = get(getState(), 'router.location.search');

    if (`${pathname}${search}` !== newURI) {
        dispatch(push(newURI))
    }
})

export const routerCustomSlice = createSlice({
    // don't use router as name as it is reserved by 'history' package
    name: 'routerCustom',
    initialState,
    reducers: {},
    extraReducers(builder) {

    }
});

export default routerCustomSlice.reducer