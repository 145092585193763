import JSZip from 'jszip';
import axios from 'axios';
import { FileCategory, FileUploadLink, GetFilesUploadLinksAndS3KeysQuery, GetFilesUploadLinksAndS3KeysQueryVariables, GetS3FileDownloadLinksQuery, GetS3FileDownloadLinksQueryVariables, OwnerType, S3File, S3FileUploadInput } from 'src/API';
import { graphQLClient } from 'src/store/util';
import * as queries from '../graphql/queries';
import { GraphQLQuery, GraphQLResult } from 'aws-amplify/api';
import { useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import type { ExtendFile } from 'src/components/file-thumbnail/types';
import { buildStorageFileKey, getFileFromIndexedDB, saveFileToIndexedDB, StorageFileKey } from 'src/utils/localStorage';

export enum DownloadStatus {
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED'
}

export type UploadFileToS3AndSaveToLocalProps = {
    s3File: S3File;
    s3PreSignedUrl: string,
    file: Blob,
    onComplete?: () => void,
    onFailure?: () => void,
    onProgress?: (percent: number) => void
}

type FetchFilesDownloadLinksOutput = {
    s3Key: string;
    s3PreSignedUrl: string;
}

const fetchFilesDownloadLinks = async (s3Keys: string[]): Promise<FetchFilesDownloadLinksOutput[]> => {
    const variables: GetS3FileDownloadLinksQueryVariables = {
        s3Keys
    }
    const response: GraphQLResult<GetS3FileDownloadLinksQuery> = await graphQLClient.graphql<GraphQLQuery<GetS3FileDownloadLinksQuery>>({
        query: queries.getS3FileDownloadLinks,
        variables
    });

    if (response.errors) {
        const error = JSON.stringify(response.errors)
        console.error(error)
        throw new Error(error);
    }

    if (response.data.getS3FileDownloadLinks.length !== s3Keys.length) {
        const error = "failed to fetch all files download links"
        console.error(error)
        throw new Error(error)
    }

    return s3Keys.map((s3Key, index) => ({
        s3Key,
        s3PreSignedUrl: response.data.getS3FileDownloadLinks[index].s3PreSignedUrl
    }))
}

const localFiles: Map<StorageFileKey, LocalFile> = new Map()

// For now we always save data in memory instead of persisting into local storage
// when s3 key is loaded into redux store, we will download the file if not already exist, and save the downloaded file to the mapping
// all file object should have s3key + version number?
export type LocalFile = {
    localUrl: string
    blob: Blob
    file: File
}
const ongoingDownloads = new Map<StorageFileKey, Promise<LocalFile>>();

type GetFilesFromLocalOrRemoteProps = {
    s3Files: S3File[],
}

export async function getFilesFromLocalOrRemote({ s3Files }: GetFilesFromLocalOrRemoteProps): Promise<LocalFile[]> {
    const downloadPromises: Promise<LocalFile>[] = s3Files.map(async (s3File) => {
        const fileKey = buildStorageFileKey(s3File)

        // fetch from indexedDB
        const cachedFile = await getFileFromIndexedDB(s3File);
        if (cachedFile) {
            const localFile: LocalFile = {
                blob: cachedFile,
                localUrl: URL.createObjectURL(cachedFile),
                file: cachedFile,
            };
            localFiles.set(fileKey, localFile);
            return localFile;
        }

        if (ongoingDownloads.has(fileKey)) {
            return ongoingDownloads.get(fileKey)!;
        }

        const downloadPromise = (async () => {
            const fileDownloadLinks = await fetchFilesDownloadLinks([s3File.s3Key]);
            const s3DownloadLink = fileDownloadLinks[0];
            const response = await fetch(s3DownloadLink.s3PreSignedUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch the file ${s3DownloadLink.s3Key}`);
            }
            const blob = await response.blob();
            const localUrl = URL.createObjectURL(blob);
            const filename = s3DownloadLink.s3Key.split("/").at(-1)!!
            const file = new File([blob], filename, { type: blob.type })
            const extendedFile: ExtendFile = Object.assign(file, {
                path: filename, // Adding the path
                preview: localUrl // Adding the preview URL
            });
            const localFile: LocalFile = {
                blob,
                localUrl,
                file: extendedFile,
            };
            // save to indexedDB
            await saveFileToIndexedDB(s3File, file);
            localFiles.set(fileKey, localFile);
            return localFile;
        })();

        ongoingDownloads.set(fileKey, downloadPromise);

        try {
            const localFile = await downloadPromise;
            return localFile;
        } finally {
            ongoingDownloads.delete(fileKey);
        }
    });

    return Promise.all(downloadPromises);
}

type FileUploadOutput = {
    uploadLink: FileUploadLink,
    file: File
}

type FileUploadInput = S3FileUploadInput & {
    file: File,
}

export async function genS3UploadURLsForNewFiles(inputs: FileUploadInput[]): Promise<FileUploadOutput[]> {
    const variables: GetFilesUploadLinksAndS3KeysQueryVariables = {
        fileUploadInputs: inputs.map(i => ({
            ownerID: i.ownerID,
            ownerType: i.ownerType,
            fileName: i.fileName,
            contentType: i.contentType,
            documentType: i.documentType,
        }))
    }
    const response: GraphQLResult<GetFilesUploadLinksAndS3KeysQuery> = await graphQLClient.graphql<GraphQLQuery<GetFilesUploadLinksAndS3KeysQuery>>({
        query: queries.getFilesUploadLinksAndS3Keys,
        variables
    });
    if (response.errors) {
        throw new Error(JSON.stringify(response.errors));
    }
    return response.data.getFilesUploadLinksAndS3Keys.map((x, index) => ({
        uploadLink: x,
        file: inputs[index].file
    }))
}

export async function uploadFileToS3AndSaveToLocal(props: UploadFileToS3AndSaveToLocalProps) {
    try {
        await axios.put(props.s3PreSignedUrl, props.file, {
            headers: {
                'Content-Type': props.file.type,
            },
            onUploadProgress: (progressEvent) => {
                const progress = progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0;
                if (props.onProgress) {
                    props.onProgress(progress);
                }
            },
        });
        const storageFileKey = buildStorageFileKey(props.s3File)
        const file = new File([props.file], props.s3File.s3Key.split("/").at(-1)!!, { type: props.file.type })
        localFiles.set(storageFileKey, {
            blob: props.file,
            localUrl: URL.createObjectURL(props.file),
            file
        })

        // save to indexedDB
        await saveFileToIndexedDB(props.s3File, file);

        if (props.onComplete) {
            props.onComplete()
        }
    }
    catch (error) {
        console.error(error)
        if (props.onFailure) {
            props.onFailure();
        }
        else {
            throw error
        }
    }
}

export function useLoadFilesFromS3Files(s3Files: S3File[]) {
    const loading = useBoolean();
    const [localUrls, setLocalUrls] = useState<LocalFile[] | undefined>(undefined);

    const stableS3Files = useMemo(
        () => s3Files,
        // Important - ensure stable reference of S3File input (even if it's the same array values, but with a different reference)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(s3Files)] // Safely handle the change by serializing the array
    );

    useEffect(() => {
        const load = async () => {
            loading.onTrue();
            // Pass keys and versions from S3File to the remote fetch method
            return getFilesFromLocalOrRemote({
                s3Files: stableS3Files
            });
        };

        load()
            .then((x) => {
                setLocalUrls(x);
                loading.onFalse();
            })
            .catch((error) => {
                console.error('Failed to load files from S3:', error);
                loading.onFalse();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stableS3Files]);

    return {
        loading: loading.value,
        localUrls,
    };
}