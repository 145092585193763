import Stack from '@mui/material/Stack';
import { LanguagePopover } from './language-popover';
import { memo } from 'react';
import { AccountDrawer } from '../components/account-drawer';
import { SettingsButton } from '../components/settings-button';
import { useAccountDrawerData } from '../main/config-navigation';

function HeaderEndStack() {
    const accountDrawerData = useAccountDrawerData()
    return (
        <Stack
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={{ xs: 0.5, sm: 1 }}
        >
            <SettingsButton data-slot="settings" />
            {accountDrawerData && <AccountDrawer data-slot="account" data={accountDrawerData} />}
        </Stack>
    )
}

export default memo(HeaderEndStack);