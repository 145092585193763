import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RobotFAQ, ListRobotFAQsByOrganizationIDQueryVariables, ListRobotFAQsByOrganizationIDQuery } from "../../API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import { RootState, AppDispatch, store } from '../store'
import { graphQLClient } from '../util'
import { getCurrentSelectedOrganization } from '../authentication/selectedOrganizationHook';
import { updateFetchStatus } from './robotConciergeSlice';

export const fetchRobotFAQsKey = 'fetchRobotFAQs'
export const fetchRobotFAQs = createAsyncThunk<
    {
        organizationID: string,
        robotFAQs: RobotFAQ[]
    } | undefined, // output
    void, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchRobotFAQs', async (_, { dispatch, getState }) => {
    if (getState().robotConcierge.fetchStatus[fetchRobotFAQsKey] === 'pending') {
        return undefined;
    }
    dispatch(updateFetchStatus({
        fetchKey: fetchRobotFAQsKey,
        status: 'pending'
    }))

    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === currentOrganizationID)

    // already fetched
    if (organizationState?.robotFAQs) {
        return undefined
    }

    let shouldFetch = true
    let nextToken = null
    let robotFAQs: RobotFAQ[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListRobotFAQsByOrganizationIDQueryVariables = {
            organizationID: currentOrganizationID,
            nextToken
        }
        const response: GraphQLResult<ListRobotFAQsByOrganizationIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListRobotFAQsByOrganizationIDQuery>>({
            query: queries.listRobotFAQsByOrganizationID,
            variables
        });
        nextToken = response.data?.listRobotFAQsByOrganizationID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        robotFAQs = robotFAQs.concat(response.data!!.listRobotFAQsByOrganizationID!!.robotFAQs!! as RobotFAQ[])
    }

    return {
        organizationID: currentOrganizationID,
        robotFAQs
    }
})

export default fetchRobotFAQs