import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { Icon } from 'blockly/core/icons';
import { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavList } from 'src/components/nav-section/vertical/nav-list';
import { paths } from 'src/routes/paths';
import { Variant } from '@mui/material/styles/createTypography';

interface NavBarButtonProps {
    onClick: () => void,
    text?: string,
    textVariantOverride?: Variant,
    icon?: NavBarIconType,
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
}

export type NavBarIconType = "home" | "allProjects" | "copy" | "create" | "qrcode" | "edit" | "save"

const NavBarIcon: Record<NavBarIconType, string> = {
    home: "solar:home-angle-bold-duotone",
    allProjects: "solar:checklist-minimalistic-bold-duotone",
    copy: "solar:copy-bold-duotone",
    create: "solar:add-square-bold-duotone",
    qrcode: "solar:qr-code-bold-duotone",
    edit: "solar:pen-new-square-bold-duotone",
    save: "solar:file-check-bold-duotone"
}
function NavBarButton({ onClick, text, icon, color, textVariantOverride }: NavBarButtonProps) {
    return (
        <Button onClick={onClick}>
            {icon && <Iconify icon={NavBarIcon[icon] as string} width={20} height={20} color={color ?? 'text.secondary'} />}
            <Typography
                noWrap
                sx={{ pl: 0.25 }} fontWeight='fontWeightSemiBold' variant={textVariantOverride || 'overline'} color={color ?? 'text.secondary'}>
                {text}
            </Typography>
        </Button >
    );
}

export default NavBarButton