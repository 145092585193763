import { uploadData, downloadData, remove } from 'aws-amplify/storage'
import * as ProfileFiles from '../store/blocklyProjects/projectFiles'
import { FileCategory } from '../API';

function getBlocklyProjectS3Key(projectId: string, fileName: string, fileCategory: FileCategory) {
    return `blockly-projects/${projectId}/${fileCategory}/${fileName}`
}

export const TEACHABLE_MACHINE_EXTENSIONS = new Set(['.zip'])
export const IMAGE_EXTENSIONS = new Set(['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.ico'])
export const VIDEO_EXTENSIONS = new Set(['.mp4', '.mov', '.avi', '.mkv', '.webm'])
export const AUDIO_EXTENSIONS = new Set(['.mp3', '.wav', '.ogg', '.acc'])


// downloadable also means previewable
export function isFileDownloadableAndPreviewable(fileName: string): boolean {
    // TODO this approach of converting to lowercase is still a bit error prone
    return IMAGE_EXTENSIONS.has(('.' + fileName.split('.').pop()).toLowerCase())
}

export enum S3FileDownloadErrorType {
    FILE_DOES_NOT_EXIST = "FILE_DOES_NOT_EXIST",
    OTHERS = "OTHERS"
}

export type S3FileDownloadResult = {
    file?: File,
    category: FileCategory,
    errorType?: S3FileDownloadErrorType,
    errorMessage?: string
}

const STORAGE_ACCESS_LEVEL = 'protected'

function is404Error(error: Error): boolean {
    return error.name.includes('NoSuchKey') || error.message.includes('NoSuchKey')
}

export async function downloadBlocklyProjectFileFromLocalOrS3(filename: string, category: FileCategory, projectID: string, onError: (result: S3FileDownloadResult) => void, onSuccess: (result: S3FileDownloadResult) => void): Promise<S3FileDownloadResult> {
    try {
        if (!isFileDownloadableAndPreviewable(filename)) {
            throw Error(`file ${filename} is not downloadable`)
        }
        const fileInLocalStorage = ProfileFiles.getFileFromProject(filename, category)
        let file;
        if (!fileInLocalStorage) {
            // Specify the key of the file you want to retrieve from the S3 bucket
            const { body, eTag } = await downloadData({
                path: ({ identityId }) => `${STORAGE_ACCESS_LEVEL}/${identityId}/${getBlocklyProjectS3Key(projectID, filename, category)}`,
                options: {
                    onProgress: (event) => {
                        console.log(`File ${filename} bytes downloaded: ${event.transferredBytes}`);
                    } // optional progress callback
                }
            }).result;

            const blob = await body.blob()
            if (!blob) {
                // TODO log error
                throw Error("Failed to download file " + filename)
            }
            // Create a File from the Blob
            file = new File([blob], filename, { type: blob.type });
            // TODO can actually get rid of the global file storage. We simply store the local created objectURL to the store when it has been downloaded. For uploads, we can just do the same, then forget about the file object when upload has finished.
            ProfileFiles.addProjectFile(file, category)
        }
        else {
            file = fileInLocalStorage.file
        }
        const out = { file, category }
        onSuccess(out)
        return out;
    } catch (e) {
        // TODO log error
        console.error(e)
        const error = e as Error
        const result = {
            category,
            errorType: is404Error(error) ? S3FileDownloadErrorType.FILE_DOES_NOT_EXIST : S3FileDownloadErrorType.OTHERS,
            errorMessage: error.message
        }
        onError(result)
        return result
    }
}

type UploadFileForBlocklyProjectProps = {
    validatedFile: File,
    projectID: string,
    fileCategory: FileCategory,
    completeCallback: () => any,
    progressCallback: (percentComplete: number) => any,
    errorCallback: (err: any) => any
}

export async function uploadBlocklyProjectFileToS3({ validatedFile, fileCategory, projectID, completeCallback, progressCallback, errorCallback }: UploadFileForBlocklyProjectProps) {
    try {
        const { result } = await uploadData({
            path: ({ identityId }) => `${STORAGE_ACCESS_LEVEL}/${identityId}/${getBlocklyProjectS3Key(projectID, validatedFile.name, fileCategory)}`,
            data: validatedFile,
            options: {
                onProgress: ({ transferredBytes, totalBytes }) => {
                    if (totalBytes) {
                        const percentCompleted = Math.round((transferredBytes / totalBytes) * 100)
                        progressCallback(percentCompleted)
                    }
                }
            }
        });

        completeCallback()
    }
    catch (error) {
        errorCallback(error)
    }
}

// TODO remove can fail if file doesn't belong to the identity
export async function removeBlocklyProjectFileFromS3(validatedFileName: string, projectID: string, fileCategory: FileCategory) {
    try {
        await remove({
            path: ({ identityId }) => `${STORAGE_ACCESS_LEVEL}/${identityId}/${getBlocklyProjectS3Key(projectID, validatedFileName, fileCategory)}`
        });
    } catch (e) {
        // TODO log. This can happen when file doesn't exist - so we only log non-404 cases
        if (!is404Error(e as Error)) {
            console.log(e)
        }
    }
}