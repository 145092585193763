import { createAsyncThunk } from '@reduxjs/toolkit'
import { OwnerType, MachineLearningModelType, MachineLearningProject, ListMachineLearningProjectsByIdAndModelTypeQueryVariables, ListMachineLearningProjectsByIdAndModelTypeQuery, CreateMachineLearningProjectMutationVariables, CreateMachineLearningProjectMutation } from "../../API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { RootState, AppDispatch } from '../store'
import { graphQLClient } from '../util'
import { CurrentMachineLearningProject } from './types';

export const createMachineLearningProject = createAsyncThunk<
    CurrentMachineLearningProject,
    {
        mutationVariables: CreateMachineLearningProjectMutationVariables,
    }, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('machineLearningProjects/createMachineLearningProject', async ({ mutationVariables }, { dispatch, getState }) => {
    const { ownerID, ownerType, modelType, projectName } = mutationVariables

    const variables: CreateMachineLearningProjectMutationVariables = {
        ownerID,
        ownerType,
        modelType,
        projectName
    }
    const response: GraphQLResult<CreateMachineLearningProjectMutation> = await graphQLClient.graphql<GraphQLQuery<CreateMachineLearningProjectMutation>>({
        query: mutations.createMachineLearningProject,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const data = response.data.createMachineLearningProject!! as MachineLearningProject
    return {
        project: data,
        otherData: {
            classes: data.classes.map(c => ({ images: [] })),
            modelJsonDownloadS3Key: undefined,
            modelWeightDownloadS3Key: undefined,
        },
        trainingStatus: undefined
    }
})