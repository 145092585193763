import { createAsyncThunk } from '@reduxjs/toolkit'
import { OwnerType, MachineLearningModelType, MachineLearningProject, ListMachineLearningProjectsByIdAndModelTypeQueryVariables, ListMachineLearningProjectsByIdAndModelTypeQuery, CreateMachineLearningProjectMutationVariables, CreateMachineLearningProjectMutation, GetMachineLearningProjectV2QueryVariables, GetMachineLearningProjectV2Query, GetS3FileDownloadLinksQueryVariables, GetS3FileDownloadLinksQuery, S3File } from "../../API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { RootState, AppDispatch } from '../store'
import { graphQLClient } from '../util'
import { CurrentMLProjectOtherData, CurrentMachineLearningProject } from './types';
import { getFilesFromLocalOrRemote } from 'src/fileStorage/fileManager';
import JSZip from 'jszip';

type SelectMachineLearningProjectProps = {
    queryVariables: GetMachineLearningProjectV2QueryVariables,
}

const fetchClassImages = async (classSamplesFileDownloadS3Files: S3File[]): Promise<string[][]> => {
    const classSamplesZipFiles = await getFilesFromLocalOrRemote({ s3Files: classSamplesFileDownloadS3Files })

    const classSampleImagesBase64 = await classSamplesZipFiles.map(async classSamplesZipFile => {
        try {
            const imagesZip = await JSZip.loadAsync(classSamplesZipFile.blob);
            // Extract image files and sort them alphabetically
            const imageFiles: JSZip.JSZipObject[] = []
            imagesZip.forEach((relativePath, zipEntry) => {
                imageFiles.push(zipEntry);
            });

            imageFiles.sort((a, b) => a.name.localeCompare(b.name));

            // Read each image file as a base64 string
            const imagePromises = imageFiles.map(file =>
                file.async('blob').then(blob => {
                    return new Promise<string>((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            resolve(reader.result as string);
                        };
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                })
            );

            const sortedImages = await Promise.all(imagePromises);
            return sortedImages
        } catch (error) {
            return [] // s3 key doesn't exist
        }
    })

    return Promise.all(classSampleImagesBase64)
}

const fetchOtherData = async (project: MachineLearningProject): Promise<CurrentMLProjectOtherData> => {
    const classSamplesFileS3Files = project.classes.map(c => c.samplesZipS3File)
    try {
        const classImages = await fetchClassImages(classSamplesFileS3Files)
        return {
            classes: classImages.map(images => ({ images })),
            modelJsonDownloadS3Key: `${project.modelS3RootDirectory}/model.json`,
            modelWeightDownloadS3Key: `${project.modelS3RootDirectory}/model.weights.bin`
        }
    }
    catch (_) {
        return {
            classes: classSamplesFileS3Files.map(_ => ({ images: [] })),
            modelJsonDownloadS3Key: undefined,
            modelWeightDownloadS3Key: undefined
        }
    }

}

export const selectMachineLearningProject = createAsyncThunk<
    {
        project: CurrentMachineLearningProject,
        fetchedFromServer: boolean
    },
    SelectMachineLearningProjectProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('machineLearningProjects/selectMachineLearningProject', async ({ queryVariables }: SelectMachineLearningProjectProps, { dispatch, getState }) => {
    const { id } = queryVariables
    // is the same as current project
    const currentProject = getState().machineLearningProjects?.currentProject
    if (currentProject && id === currentProject.project.id) {
        return {
            project: currentProject,
            fetchedFromServer: false
        }
    }

    // already stored in the projects list
    const existing = getState().machineLearningProjects.userProjects?.find(project => project.id === id)
    if (existing) {
        const otherData = await fetchOtherData(existing)
        return {
            project: {
                project: existing,
                otherData,
                trainingStatus: undefined
            },
            fetchedFromServer: false
        }
    }

    const variables: GetMachineLearningProjectV2QueryVariables = {
        id
    }
    const response: GraphQLResult<GetMachineLearningProjectV2Query> = await graphQLClient.graphql<GraphQLQuery<GetMachineLearningProjectV2Query>>({
        query: queries.getMachineLearningProjectV2,
        variables
    });

    const project = response.data.getMachineLearningProjectV2

    if (!project) {
        throw new Error("Project doesn't exist or user have no permission")
    }

    const otherData = await fetchOtherData(project)
    return {
        project: {
            project,
            otherData,
            trainingStatus: undefined
        },
        fetchedFromServer: true
    }
})