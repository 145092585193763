import Box from "@mui/material/Box"

type IconProps = {
    fillColor?: string
}

export function IconMenuItem() {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.32" d="M15.2798 4.5H4.7202C3.77169 4.5 3 5.06057 3 5.75042C3 6.43943 3.77169 7 4.7202 7H15.2798C16.2283 7 17 6.43943 17 5.75042C17 5.06054 16.2283 4.5 15.2798 4.5Z" fill="currentColor" />
        <path d="M19.2798 10.75H8.7202C7.77169 10.75 7 11.3106 7 12.0004C7 12.6894 7.77169 13.25 8.7202 13.25H19.2798C20.2283 13.25 21 12.6894 21 12.0004C21 11.3105 20.2283 10.75 19.2798 10.75Z" fill="currentColor" />
        <path d="M15.2798 17H4.7202C3.77169 17 3 17.5606 3 18.2504C3 18.9394 3.77169 19.5 4.7202 19.5H15.2798C16.2283 19.5 17 18.9394 17 18.2504C17 17.5606 16.2283 17 15.2798 17Z" fill="currentColor" />
    </svg>)
}

export function IconCodelab() {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3035 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34677 14.3385 3.47362 14.722 2.0318C13.9279 2.01186 13.0248 2 12 2C8.51575 2 6.43945 2.13682 5.26285 2.26379C4.39116 2.35785 3.71902 2.94826 3.5558 3.80967C3.30175 5.15055 3 7.65725 3 12C3 16.3428 3.30175 18.8494 3.5558 20.1903C3.71902 21.0518 4.39116 21.6422 5.26285 21.7362C6.43945 21.8631 8.51575 22 12 22C15.4843 22 17.5606 21.8631 18.7372 21.7362C19.6089 21.6422 20.281 21.0518 20.4442 20.1903C20.6982 18.8494 21 16.3428 21 12C21 10.5445 20.9661 9.2952 20.9114 8.22695ZM8 13C7.4477 13 7 12.5523 7 12C7 11.4477 7.4477 11 8 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H8ZM8 17.5C7.4477 17.5 7 17.0523 7 16.5C7 15.9477 7.4477 15.5 8 15.5H15C15.5523 15.5 16 15.9477 16 16.5C16 17.0523 15.5523 17.5 15 17.5H8Z" fill="currentColor" />
        <path d="M7 16.5C7 17.0523 7.4477 17.5 8 17.5H15C15.5523 17.5 16 17.0523 16 16.5C16 15.9477 15.5523 15.5 15 15.5H8C7.4477 15.5 7 15.9477 7 16.5Z" fill="currentColor" />
        <path d="M7 12C7 12.5523 7.4477 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8C7.4477 11 7 11.4477 7 12Z" fill="currentColor" />
        <path d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3036 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34675 14.3384 3.47357 14.722 2.03174C14.722 2.03174 15.9461 2.49994 18.1961 4.74994C20.4461 6.99995 20.9114 8.22695 20.9114 8.22695Z" fill="currentColor" />
    </svg>)
}

export function IconDashboard() {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.32" d="M21.1808 16.9703C20.8971 17.6255 20.2225 18 19.5086 18H14.8154C14.8462 17.9145 14.8735 17.8269 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.93642 16.7899 8.97782 17.4291 9.18451 18H4.49141C3.77747 18 3.10288 17.6255 2.81918 16.9703C2.29212 15.7533 2 14.4108 2 13C2 7.47715 6.47715 3 12 3C17.5229 3 22 7.47715 22 13C22 14.4108 21.7079 15.7533 21.1808 16.9703Z" fill="currentColor" />
        <path d="M14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.6742 17.7856 9.62375 19.459 11.2238 19.8953C12.8238 20.3315 14.4684 19.3654 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516Z" fill="currentColor" />
    </svg>)
}

export function IconFAQManagement() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24">
        <path opacity="0.65"
            fill="currentColor"
            d="M18 15H6l-4 4V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1m5-6v14l-4-4H8a1 1 0 0 1-1-1v-1h14V8h1a1 1 0 0 1 1 1M8.19 4c-.87 0-1.57.2-2.11.59c-.52.41-.78.98-.77 1.77l.01.03h1.93c.01-.3.1-.53.28-.69a1 1 0 0 1 .66-.23c.31 0 .57.1.75.28c.18.19.26.45.26.75c0 .32-.07.59-.23.82c-.14.23-.35.43-.61.59c-.51.34-.86.64-1.05.91C7.11 9.08 7 9.5 7 10h2c0-.31.04-.56.13-.74s.26-.36.51-.52c.45-.24.82-.53 1.11-.93s.44-.81.44-1.31c0-.76-.27-1.37-.81-1.82C9.85 4.23 9.12 4 8.19 4M7 11v2h2v-2zm6 2h2v-2h-2zm0-9v6h2V4z" />
    </svg>)
}

export function IconEmptyContent() {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.48">
                <path opacity="0.48" fillRule="evenodd" clipRule="evenodd" d="M21.1902 86.0659L22.6459 87.5217L24.1062 86.0619L24.7302 86.6858L23.2696 88.1453L24.7262 89.601L24.1005 90.2266L22.6438 88.771L21.1845 90.2306L20.5605 89.6067L22.0201 88.1474L20.5645 86.6915L21.1902 86.0659Z" fill="#919EAB" />
                <path opacity="0.48" fillRule="evenodd" clipRule="evenodd" d="M87.2381 12.7675L88.6937 14.2233L90.1541 12.7635L90.7781 13.3873L89.3175 14.8469L90.7741 16.3025L90.1483 16.9281L88.6916 15.4725L87.2323 16.9321L86.6083 16.3083L88.0679 14.849L86.6124 13.393L87.2381 12.7675Z" fill="#919EAB" />
                <path opacity="0.72" d="M61.4784 104.835C61.7522 104.562 62.1233 104.409 62.5101 104.409C62.897 104.409 63.268 104.562 63.5419 104.835L64.5809 105.874C64.717 106.009 64.8258 106.171 64.8995 106.348C64.9734 106.526 65.0115 106.716 65.0115 106.909C65.0115 107.101 64.9734 107.291 64.8995 107.469C64.8258 107.646 64.7178 107.807 64.5817 107.942L63.5419 108.982C63.268 109.255 62.897 109.409 62.5101 109.409C62.1233 109.409 61.7522 109.255 61.4784 108.982L60.4393 107.943C60.3032 107.808 60.1945 107.646 60.1207 107.469C60.0468 107.291 60.0088 107.101 60.0088 106.909C60.0088 106.716 60.0468 106.526 60.1207 106.348C60.1944 106.171 60.3024 106.01 60.4385 105.875L61.4784 104.835Z" fill="#919EAB" />
                <path fillRule="evenodd" clipRule="evenodd" d="M96.6978 39.0406C96.6977 39.0406 96.6979 39.0405 96.6978 39.0406L95.6585 40.0796L95.2196 39.6406L95.6571 40.081C95.6368 40.1011 95.6207 40.1251 95.6097 40.1516C95.5987 40.178 95.593 40.2063 95.593 40.235C95.593 40.2636 95.5987 40.292 95.6097 40.3184C95.6207 40.3448 95.6368 40.3688 95.6571 40.389L95.6585 40.3904L96.6978 41.4294C96.6977 41.4293 96.6979 41.4295 96.6978 41.4294C96.739 41.4704 96.7948 41.4935 96.8529 41.4935C96.911 41.4935 96.9667 41.4705 97.0078 41.4296C97.0077 41.4297 97.0079 41.4296 97.0078 41.4296L98.0487 40.389C98.069 40.3688 98.0851 40.3448 98.0961 40.3184C98.1072 40.2919 98.1128 40.2635 98.1128 40.235C98.1128 40.2064 98.1072 40.1781 98.0961 40.1516C98.0851 40.1251 98.069 40.1011 98.0487 40.081L98.0473 40.0796L97.008 39.0406C97.008 39.0405 97.0081 39.0406 97.008 39.0406C96.9669 38.9997 96.911 38.9764 96.8529 38.9764C96.7948 38.9764 96.739 38.9996 96.6978 39.0406ZM94.7813 39.2011L95.8211 38.1616C96.095 37.8884 96.466 37.735 96.8529 37.735C97.2397 37.735 97.6108 37.8884 97.8847 38.1616L97.8852 38.1621L98.9237 39.2003C98.924 39.2005 98.9242 39.2008 98.9244 39.201C99.0606 39.3365 99.1686 39.4974 99.2423 39.6747C99.3162 39.8521 99.3543 40.0426 99.3543 40.235C99.3543 40.4274 99.3162 40.6178 99.2423 40.7953C99.1686 40.9725 99.0606 41.1335 98.9245 41.2689C98.9242 41.2692 98.924 41.2694 98.9237 41.2697L97.8847 42.3084C97.6108 42.5816 97.2397 42.735 96.8529 42.735C96.466 42.735 96.095 42.5816 95.8211 42.3084L94.7821 41.2697C94.7818 41.2694 94.7816 41.2691 94.7813 41.2689C94.6452 41.1334 94.5372 40.9725 94.4635 40.7953C94.3896 40.6177 94.3516 40.4273 94.3516 40.235C94.3516 40.0427 94.3896 39.8522 94.4635 39.6747C94.5372 39.4974 94.6452 39.3365 94.7813 39.2011Z" fill="#919EAB" />
                <path opacity="0.48" fillRule="evenodd" clipRule="evenodd" d="M104.552 66.3173C104.701 66.2788 104.858 66.2779 105.008 66.3152C105.173 66.356 105.322 66.4408 105.442 66.5601C105.561 66.6796 105.646 66.8294 105.687 66.9934C105.724 67.1436 105.723 67.3004 105.685 67.4496L105.109 70.541C105.062 70.7838 104.909 71.1333 104.522 71.2523C104.135 71.3713 103.812 71.1677 103.638 70.9932L101.009 68.3652C100.831 68.1887 100.628 67.865 100.748 67.4773C100.868 67.0898 101.219 66.9374 101.464 66.8921C101.465 66.8921 101.465 66.892 101.465 66.892L104.552 66.3173ZM104.448 67.5539L102.291 67.9553L104.046 69.7099L104.448 67.5539Z" fill="#919EAB" />
                <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M102.46 94.2659C102.609 94.2274 102.766 94.2265 102.916 94.2638C103.08 94.3046 103.23 94.3894 103.349 94.5087C103.469 94.6282 103.554 94.778 103.594 94.942C103.632 95.0922 103.631 95.249 103.592 95.3982L103.016 98.4896C102.97 98.7324 102.817 99.0819 102.43 99.2009C102.042 99.3199 101.72 99.1163 101.545 98.9418L98.9167 96.3138C98.7386 96.1373 98.5356 95.8136 98.6558 95.4259C98.776 95.0384 99.1263 94.886 99.3721 94.8407C99.3724 94.8407 99.3727 94.8406 99.373 94.8406L102.46 94.2659ZM102.356 95.5025L100.199 95.9039L101.954 97.6585L102.356 95.5025Z" fill="#637381" />
                <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M12.5004 72.4729C12.3186 72.4729 12.1408 72.5268 11.9896 72.6278C11.8383 72.7289 11.7205 72.8724 11.651 73.0402C11.5814 73.2081 11.5632 73.3929 11.5987 73.5711C11.6341 73.7494 11.7217 73.9131 11.8502 74.0416C11.9787 74.1701 12.1425 74.2576 12.321 74.2931C12.4996 74.3286 12.6845 74.3104 12.8523 74.2409C13.0203 74.1714 13.1639 74.0535 13.265 73.9023C13.366 73.7512 13.4199 73.5736 13.4199 73.3918C13.4199 73.1482 13.323 72.9144 13.1506 72.7421C12.9783 72.5697 12.7444 72.4729 12.5004 72.4729ZM11.1113 71.3132C11.5225 71.0385 12.0059 70.8918 12.5004 70.8918C13.1635 70.8918 13.7995 71.1551 14.2684 71.624C14.7374 72.0928 15.0009 72.7288 15.0009 73.3918C15.0009 73.8864 14.8542 74.3697 14.5794 74.7808C14.3047 75.1919 13.9142 75.5124 13.4572 75.7017C13.0002 75.8909 12.4975 75.9402 12.0127 75.8438C11.5278 75.7474 11.0822 75.5094 10.7324 75.1597C10.3827 74.8101 10.1446 74.3647 10.0481 73.8797C9.95156 73.3947 10.0011 72.8919 10.1904 72.435C10.3797 71.9781 10.7002 71.5878 11.1113 71.3132Z" fill="#637381" />
                <path opacity="0.8" fillRule="evenodd" clipRule="evenodd" d="M54.5424 18.4017C54.3606 18.4017 54.1828 18.4556 54.0316 18.5567C53.8803 18.6577 53.7625 18.8012 53.693 18.969C53.6234 19.1369 53.6052 19.3217 53.6407 19.5C53.6761 19.6782 53.7637 19.8419 53.8922 19.9705C54.0207 20.0989 54.1845 20.1865 54.363 20.222C54.5416 20.2575 54.7265 20.2393 54.8943 20.1698C55.0623 20.1002 55.2059 19.9824 55.307 19.8312C55.408 19.68 55.4619 19.5024 55.4619 19.3207C55.4619 19.077 55.365 18.8432 55.1926 18.6709C55.0203 18.4986 54.7864 18.4017 54.5424 18.4017ZM53.1533 17.242C53.5645 16.9674 54.0479 16.8207 54.5424 16.8207C55.2055 16.8207 55.8415 17.084 56.3104 17.5528C56.7794 18.0216 57.0429 18.6576 57.0429 19.3207C57.0429 19.8152 56.8962 20.2986 56.6214 20.7097C56.3467 21.1207 55.9562 21.4413 55.4992 21.6305C55.0422 21.8198 54.5395 21.869 54.0547 21.7726C53.5698 21.6762 53.1242 21.4383 52.7744 21.0886C52.4247 20.7389 52.1865 20.2935 52.09 19.8085C51.9936 19.3235 52.0431 18.8207 52.2324 18.3639C52.4217 17.9069 52.7422 17.5166 53.1533 17.242Z" fill="#637381" />
            </g>
            <g opacity="0.48">
                <g filter="url(#filter0_di_2575_2852044)">
                    <path d="M27.9998 49.238H91.9996V78.1903C91.9996 82.3982 88.5884 85.8094 84.3806 85.8094H35.6188C31.4109 85.8094 27.9998 82.3982 27.9998 78.1903V49.238Z" fill="#DFE3E8" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M37.9044 39.3334C36.6242 39.3334 35.6187 40.3389 35.6187 41.6191C35.6187 42.8989 36.6242 43.9048 37.9044 43.9048C39.1845 43.9048 40.1901 42.8989 40.1901 41.6191C40.1901 40.3389 39.1845 39.3334 37.9044 39.3334Z" fill="#C4CDD5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.1424 39.3334C43.8623 39.3334 42.8567 40.3389 42.8567 41.6191C42.8567 42.8989 43.8623 43.9048 45.1424 43.9048C46.4226 43.9048 47.4281 42.8989 47.4281 41.6191C47.4281 40.3389 46.4226 39.3334 45.1424 39.3334Z" fill="#C4CDD5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M52.3805 39.3334C51.1004 39.3334 50.0948 40.3389 50.0948 41.6191C50.0948 42.8989 51.1004 43.9048 52.3805 43.9048C53.6607 43.9048 54.6662 42.8989 54.6662 41.6191C54.6662 40.3389 53.6607 39.3334 52.3805 39.3334Z" fill="#C4CDD5" />
                <g filter="url(#filter1_di_2575_2852044)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M91.9996 40.0954V49.2381H27.9998L27.9998 40.0959C27.9998 36.7294 30.7289 34 34.0955 34H85.9044C89.2707 34 91.9996 36.7291 91.9996 40.0954ZM37.9045 39.3333C36.6243 39.3333 35.6188 40.3389 35.6188 41.619C35.6188 42.8989 36.6243 43.9047 37.9045 43.9047C39.1846 43.9047 40.1902 42.8989 40.1902 41.619C40.1902 40.3389 39.1846 39.3333 37.9045 39.3333ZM45.1426 39.3333C43.8624 39.3333 42.8569 40.3389 42.8569 41.619C42.8569 42.8989 43.8624 43.9047 45.1426 43.9047C46.4227 43.9047 47.4283 42.8989 47.4283 41.619C47.4283 40.3389 46.4227 39.3333 45.1426 39.3333ZM50.0949 41.619C50.0949 40.3389 51.1005 39.3333 52.3806 39.3333C53.6608 39.3333 54.6664 40.3389 54.6664 41.619C54.6664 42.8989 53.6608 43.9047 52.3806 43.9047C51.1005 43.9047 50.0949 42.8989 50.0949 41.619Z" fill="#919EAB" />
                </g>
                <path opacity="0.48" fillRule="evenodd" clipRule="evenodd" d="M82.3255 54.0601H63.7209C62.6926 54.0601 61.8605 54.8908 61.8605 55.9175V78.2063C61.8605 79.2329 62.6926 80.0637 63.7209 80.0637H82.3255C83.3539 80.0637 84.186 79.2329 84.186 78.2063V55.9175C84.186 54.8908 83.3539 54.0601 82.3255 54.0601Z" fill="#637381" />
                <path opacity="0.24" fillRule="evenodd" clipRule="evenodd" d="M37.6744 57.7749H45.1163C46.1446 57.7749 46.9767 56.9441 46.9767 55.9175C46.9767 54.8908 46.1446 54.0601 45.1163 54.0601H37.6744C36.6461 54.0601 35.814 54.8908 35.814 55.9175C35.814 56.9441 36.6461 57.7749 37.6744 57.7749ZM35.814 63.3471C35.814 62.3204 36.6461 61.4897 37.6744 61.4897H56.279C57.3074 61.4897 58.1395 62.3204 58.1395 63.3471C58.1395 64.3737 57.3074 65.2045 56.279 65.2045H37.6744C36.6461 65.2045 35.814 64.3737 35.814 63.3471ZM37.6744 68.9193C36.6461 68.9193 35.814 69.75 35.814 70.7767C35.814 71.8033 36.6461 72.6341 37.6744 72.6341H56.279C57.3074 72.6341 58.1395 71.8033 58.1395 70.7767C58.1395 69.75 57.3074 68.9193 56.279 68.9193H37.6744ZM35.814 78.2063C35.814 77.1796 36.6461 76.3489 37.6744 76.3489H56.279C57.3074 76.3489 58.1395 77.1796 58.1395 78.2063C58.1395 79.2329 57.3074 80.0637 56.279 80.0637H37.6744C36.6461 80.0637 35.814 79.2329 35.814 78.2063Z" fill="#637381" />
            </g>
            <defs>
                <filter id="filter0_di_2575_2852044" x="19.9998" y="41.238" width="95.9999" height="68.5713" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="8" dy="8" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2575_2852044" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2575_2852044" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.48 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2575_2852044" />
                </filter>
                <filter id="filter1_di_2575_2852044" x="19.9998" y="26" width="95.9999" height="47.238" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="8" dy="8" />
                    <feGaussianBlur stdDeviation="8" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2575_2852044" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2575_2852044" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.270588 0 0 0 0 0.309804 0 0 0 0 0.356863 0 0 0 0.48 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2575_2852044" />
                </filter>
            </defs>
        </svg>

    )
}

export function IconSun({ fillColor }: IconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9998 18.1111C15.3749 18.1111 18.1109 15.3751 18.1109 12C18.1109 8.62495 15.3749 5.88892 11.9998 5.88892C8.62471 5.88892 5.88867 8.62495 5.88867 12C5.88867 15.3751 8.62471 18.1111 11.9998 18.1111Z" fill={fillColor ?? "#637381"} />
            <g opacity="0.4">
                <path d="M10.1667 2.83333C10.1667 1.78189 10.8396 1.00805 11.891 1.00039C11.9266 1.00013 11.9629 1 12 1C12.0371 1 12.0734 1.00013 12.109 1.00039C13.1604 1.00805 13.8333 1.78189 13.8333 2.83333C13.8333 3.88481 13.1604 4.6586 12.109 4.6663C12.0734 4.66654 12.0371 4.66667 12 4.66667C11.9629 4.66667 11.9266 4.66654 11.891 4.6663C10.8396 4.6586 10.1667 3.88475 10.1667 2.83333Z" fill={fillColor ?? "#637381"} />
                <path d="M10.1667 21.1667C10.1667 22.2181 10.8396 22.9919 11.891 22.9996C11.9266 22.9999 11.9629 23 12 23C12.0371 23 12.0734 22.9999 12.109 22.9996C13.1604 22.9919 13.8333 22.2181 13.8333 21.1667C13.8333 20.1152 13.1604 19.3414 12.109 19.3337C12.0734 19.3335 12.0371 19.3333 12 19.3333C11.9629 19.3333 11.9266 19.3335 11.891 19.3337C10.8396 19.3414 10.1667 20.1152 10.1667 21.1667Z" fill={fillColor ?? "#637381"} />
                <path d="M17.1855 4.22184C17.9289 3.47836 18.9519 3.40695 19.7008 4.14502C19.7262 4.17002 19.7519 4.19562 19.7782 4.22184C19.8044 4.24806 19.83 4.27384 19.855 4.29921C20.593 5.04806 20.5216 6.07106 19.7782 6.81454C19.0347 7.55802 18.0117 7.62939 17.2628 6.89136C17.2375 6.86636 17.2117 6.84076 17.1855 6.81454C17.1592 6.78832 17.1336 6.76253 17.1086 6.73717C16.3706 5.98832 16.442 4.96532 17.1855 4.22184Z" fill={fillColor ?? "#637381"} />
                <path d="M4.22184 17.1855C3.47836 17.9289 3.40696 18.9519 4.14502 19.7008C4.17002 19.7262 4.19562 19.7519 4.22184 19.7782C4.24806 19.8044 4.27384 19.83 4.29921 19.855C5.04806 20.593 6.07106 20.5216 6.81454 19.7782C7.55802 19.0347 7.62939 18.0117 6.89136 17.2628C6.86636 17.2375 6.84076 17.2117 6.81454 17.1855C6.78832 17.1592 6.76253 17.1336 6.73717 17.1086C5.98832 16.3706 4.96532 16.442 4.22184 17.1855Z" fill={fillColor ?? "#637381"} />
                <path d="M2.83333 13.8333C1.78189 13.8333 1.00805 13.1604 1.00039 12.109C1.00013 12.0734 1 12.0371 1 12C1 11.9629 1.00013 11.9266 1.00039 11.891C1.00805 10.8396 1.78189 10.1667 2.83333 10.1667C3.88475 10.1667 4.6586 10.8396 4.6663 11.891C4.66654 11.9266 4.66667 11.9629 4.66667 12C4.66667 12.0371 4.66654 12.0734 4.6663 12.109C4.6586 13.1604 3.88475 13.8333 2.83333 13.8333Z" fill={fillColor ?? "#637381"} />
                <path d="M21.1667 13.8333C22.2181 13.8333 22.9919 13.1604 22.9996 12.109C22.9999 12.0734 23 12.0371 23 12C23 11.9629 22.9999 11.9266 22.9996 11.891C22.9919 10.8396 22.2181 10.1667 21.1667 10.1667C20.1152 10.1667 19.3414 10.8396 19.3337 11.891C19.3335 11.9266 19.3333 11.9629 19.3333 12C19.3333 12.0371 19.3335 12.0734 19.3337 12.109C19.3414 13.1604 20.1152 13.8333 21.1667 13.8333Z" fill={fillColor ?? "#637381"} />
                <path d="M4.22184 6.81454C3.47836 6.07106 3.40695 5.04806 4.14502 4.29921C4.17002 4.27384 4.19562 4.24806 4.22184 4.22184C4.24806 4.19562 4.27384 4.17002 4.29921 4.14502C5.04806 3.40695 6.07106 3.47836 6.81454 4.22184C7.55802 4.96532 7.62939 5.98832 6.89136 6.73717C6.86636 6.76253 6.84076 6.78832 6.81454 6.81454C6.78832 6.84076 6.76253 6.86636 6.73717 6.89136C5.98832 7.62939 4.96532 7.55802 4.22184 6.81454Z" fill={fillColor ?? "#637381"} />
                <path d="M17.1855 19.7782C17.9289 20.5216 18.9519 20.593 19.7008 19.855C19.7262 19.83 19.7519 19.8044 19.7782 19.7782C19.8044 19.7519 19.83 19.7262 19.855 19.7008C20.593 18.9519 20.5216 17.9289 19.7782 17.1855C19.0347 16.442 18.0117 16.3706 17.2628 17.1086C17.2375 17.1336 17.2117 17.1592 17.1855 17.1855C17.1592 17.2117 17.1336 17.2375 17.1086 17.2628C16.3706 18.0117 16.442 19.0347 17.1855 19.7782Z" fill={fillColor ?? "#637381"} />
            </g>
        </svg>
    )
}

export function IconMoon({ fillColor }: IconProps) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M16.9462 11.0863C16.9759 11.0874 17.0055 11.0886 17.035 11.0898C20.1966 11.2176 22.5 13.3358 22.5 16.5C22.5 19.6642 20.1966 21.7824 17.035 21.9102C15.7057 21.9639 14.0498 22 12 22C9.9502 22 8.2943 21.9639 6.965 21.9102C3.80337 21.7824 1.5 19.6642 1.5 16.5C1.5 14.0317 2.90165 12.1999 5.019 11.4529C5.2406 8.2951 7.3872 6.02435 10.6413 6.00125C10.7585 6.00045 10.878 6 11 6C11.122 6 11.2415 6.00045 11.3587 6.00125C14.4855 6.02345 16.5897 8.1208 16.9462 11.0863Z" fill={fillColor ?? "#637381"} />
            <path d="M19.2407 2.28853C19.5263 2.12002 19.5419 1.62921 19.2169 1.57222C18.1306 1.38179 16.9755 1.56344 15.9464 2.17059C14.4123 3.07575 13.5394 4.70186 13.501 6.38837C15.4283 7.12677 16.6785 8.86242 16.9459 11.0863L17.0347 11.0898C17.7391 11.1183 18.401 11.2456 19.0042 11.4612C19.6324 11.3806 20.2555 11.1732 20.8383 10.8294C21.8673 10.2222 22.5988 9.2907 22.9806 8.23415C23.0948 7.918 22.6711 7.6864 22.3855 7.8549C20.8813 8.74235 18.958 8.2157 18.0896 6.6786C17.2212 5.1415 17.7366 3.17599 19.2407 2.28853Z" fill={fillColor ?? "#637381"} />
        </svg>
    )
}

export function IconContrast({ fillColor }: IconProps) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z" fill={fillColor ?? "#637381"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z" fill={fillColor ?? "#637381"} />
        <path d="M11.1299 20.6213C9.59086 20.4566 8.12388 19.8832 6.88089 18.9607C5.63791 18.0383 4.66413 16.8002 4.06042 15.3747C4.04526 15.3389 4.03101 15.3029 4.01638 15.2669L11.1299 19.2499V20.6213ZM11.1299 17.7217L3.50026 13.4499C3.42001 12.9712 3.37975 12.4867 3.37988 12.0013C3.37988 11.726 3.39269 11.4527 3.4183 11.1816L11.1299 15.4993V17.7217ZM11.1299 13.9711L3.66401 9.79102C3.76825 9.39432 3.90072 9.00559 4.06042 8.6278C4.13729 8.44591 4.22016 8.26733 4.30905 8.09205L11.1299 11.9153V13.9711ZM11.1299 10.3866L5.0013 6.95138C5.27722 6.56735 5.5839 6.20639 5.9183 5.87205C6.02163 5.76871 6.12719 5.6683 6.23497 5.57084L11.1299 8.25191V10.3866ZM11.1299 6.73156L7.39455 4.68571C8.52175 3.96725 9.80052 3.52071 11.1299 3.38135V6.73156Z" fill={fillColor ?? "#637381"} />
    </svg>
    )
}

export function IconContrastBold({ fillColor }: IconProps) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5Z" fill={fillColor ?? "#637381"} />
        <path d="M11.13 20.62C6.84962 20.62 3.38 16.7609 3.38 12C3.38 7.23911 6.84962 3.38 11.13 3.38V20.62Z" fill={fillColor ?? "#637381"} />
    </svg>
    )
}

export function IconAlignLeft({ fillColor }: IconProps) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.53588 2.0252C4.07763 2.0806 4.36934 2.55551 4.38944 3.09972C4.43389 4.30253 4.5 6.94185 4.5 12C4.5 17.0581 4.43389 19.6974 4.38944 20.9003C4.36934 21.4445 4.07763 21.9194 3.53588 21.9748C3.38398 21.9904 3.20649 22 3 22C2.79351 22 2.61602 21.9904 2.46412 21.9748C1.92237 21.9194 1.63066 21.4445 1.61056 20.9003C1.56611 19.6974 1.5 17.0581 1.5 12C1.5 6.94185 1.56611 4.30253 1.61056 3.09972C1.63066 2.55551 1.92237 2.0806 2.46412 2.0252C2.61602 2.00967 2.79351 2 3 2C3.20649 2 3.38398 2.00967 3.53588 2.0252Z" fill={fillColor ?? "#637381"} />
        <g opacity="0.4">
            <path d="M16.4649 4.74734C16.4334 3.88857 15.9091 3.23087 15.0545 3.14106C14.3225 3.06415 13.2019 3 11.5 3C9.7981 3 8.6775 3.06415 7.94555 3.14106C7.0909 3.23087 6.56655 3.88857 6.5351 4.74734C6.51525 5.28845 6.5 6.0223 6.5 7C6.5 7.9777 6.51525 8.71155 6.5351 9.25265C6.56655 10.1115 7.0909 10.7691 7.94555 10.8589C8.6775 10.9358 9.7981 11 11.5 11C13.2019 11 14.3225 10.9358 15.0545 10.8589C15.9091 10.7691 16.4334 10.1115 16.4649 9.25265C16.4847 8.71155 16.5 7.9777 16.5 7C16.5 6.0223 16.4847 5.28845 16.4649 4.74734Z" fill={fillColor ?? "#637381"} />
            <path d="M22.4536 14.9249C22.4041 13.9629 21.7449 13.2665 20.785 13.1855C19.6588 13.0906 17.7362 13 14.5 13C11.2638 13 9.3412 13.0906 8.21495 13.1855C7.25505 13.2665 6.5959 13.9629 6.54635 14.9249C6.5195 15.4458 6.5 16.1253 6.5 17C6.5 17.8747 6.5195 18.5542 6.54635 19.0751C6.5959 20.0371 7.25505 20.7335 8.21495 20.8144C9.3412 20.9094 11.2638 21 14.5 21C17.7362 21 19.6588 20.9094 20.785 20.8144C21.7449 20.7335 22.4041 20.0371 22.4536 19.0751C22.4805 18.5542 22.5 17.8747 22.5 17C22.5 16.1253 22.4805 15.4458 22.4536 14.9249Z" fill={fillColor ?? "#637381"} />
        </g>
    </svg>
    )
}

export function IconAlignRight({ fillColor }: IconProps) {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9641 2.0252C20.4224 2.0806 20.1307 2.55551 20.1106 3.09972C20.0661 4.30253 20 6.94185 20 12C20 17.0581 20.0661 19.6974 20.1106 20.9003C20.1307 21.4445 20.4224 21.9194 20.9641 21.9748C21.116 21.9904 21.2935 22 21.5 22C21.7065 22 21.884 21.9904 22.0359 21.9748C22.5776 21.9194 22.8693 21.4445 22.8894 20.9003C22.9339 19.6974 23 17.0581 23 12C23 6.94185 22.9339 4.30253 22.8894 3.09972C22.8693 2.55551 22.5776 2.0806 22.0359 2.0252C21.884 2.00967 21.7065 2 21.5 2C21.2935 2 21.116 2.00967 20.9641 2.0252Z" fill={fillColor ?? "#637381"} />
        <g opacity="0.4">
            <path d="M8.0351 4.74734C8.06655 3.88857 8.5909 3.23087 9.44555 3.14106C10.1775 3.06415 11.2981 3 13 3C14.7019 3 15.8225 3.06415 16.5545 3.14106C17.4091 3.23087 17.9335 3.88857 17.9649 4.74734C17.9848 5.28845 18 6.0223 18 7C18 7.9777 17.9848 8.71155 17.9649 9.25265C17.9335 10.1115 17.4091 10.7691 16.5545 10.8589C15.8225 10.9358 14.7019 11 13 11C11.2981 11 10.1775 10.9358 9.44555 10.8589C8.5909 10.7691 8.06655 10.1115 8.0351 9.25265C8.01525 8.71155 8 7.9777 8 7C8 6.0223 8.01525 5.28845 8.0351 4.74734Z" fill={fillColor ?? "#637381"} />
            <path d="M2.04635 14.9249C2.0959 13.9629 2.75505 13.2665 3.71495 13.1855C4.8412 13.0906 6.76375 13 10 13C13.2362 13 15.1588 13.0906 16.2851 13.1855C17.245 13.2665 17.9041 13.9629 17.9536 14.9249C17.9805 15.4458 18 16.1253 18 17C18 17.8747 17.9805 18.5542 17.9536 19.0751C17.9041 20.0371 17.245 20.7335 16.2851 20.8144C15.1588 20.9094 13.2362 21 10 21C6.76375 21 4.8412 20.9094 3.71495 20.8144C2.75505 20.7335 2.0959 20.0371 2.04635 19.0751C2.0195 18.5542 2 17.8747 2 17C2 16.1253 2.0195 15.4458 2.04635 14.9249Z" fill={fillColor ?? "#637381"} />
        </g>
    </svg>
    )
}