import { generateClient, GraphQLQuery, GraphQLResult } from 'aws-amplify/api';
import { GraphQLVariablesV6, GraphQLResponseV6, GraphQLOptionsV6 } from '@aws-amplify/api-graphql'
// src/apiClient.ts
import axios from 'axios';

// Create a custom Axios instance with a timeout of 10 minutes
export const axiosClientWithLongTimeout = axios.create({
  timeout: 600000, // 600000 milliseconds = 10 minutes
});

export type ReducerStatus = 'pending' | 'fulfilled' | 'rejected'

export const graphQLClient = generateClient()

// const refreshToken = async () => {
//   try {
//     await Auth.currentSession();
//   } catch (error) {
//     console.error('Error refreshing token:', error);
//     throw error;
//   }
// };

// export const graphqlClient = async (query, variables) => {
//   try {
//     // Ensure token is valid or refresh if necessary
//     await refreshToken();

//     // Perform the GraphQL request
//     return await graphQLClientRaw.graphql(graphqlOperation(query, variables));
//   } catch (error) {
//     console.error('Error making GraphQL request:', error);
//     throw error;
//   }
// };