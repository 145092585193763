import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { bgBlur, varAlpha } from 'src/theme/styles';

import Logo from 'src/components/logo';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';
import HeaderEndStack from '../common/header-end-stack';
import IconButton from '@mui/material/IconButton';
import { SvgColor } from 'src/components/svg-color';
import { Searchbar } from '../common/searchbar';
import BrowseProjectsListButton from 'src/sections/codelab/buttons/BrowseProjectsListButton';
import CreateNewProjectButton from 'src/sections/codelab/buttons/CreateNewProjectButton';
import SaveCurrentProjectButton from 'src/sections/codelab/buttons/SaveCurrentProjectButton';
import CopyBlocklyProjectButton, { CopyBlocklyProjectButtonType } from 'src/sections/codelab/buttons/CopyBlocklyProjectButton';
import GenerateQRCodeButton from 'src/sections/codelab/buttons/GenerateQRCodeButton';
import ProjectNameEditorButton from 'src/sections/codelab/buttons/ProjectNameEditorButton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { usePathname, useRouter } from 'src/routes/hooks';
import { Scrollbar } from 'src/components/scrollbar';
import useIsSmallScreen from '../common/useIsSmallScreen';
import { Iconify } from 'src/components/iconify';
import MachineLearningEditProjectButton from 'src/sections/codelab/machineLearning/allProjects/components/MachineLearningEditProjectButton';
import NavBarButton from 'src/ui/navBar/NavBarButton';
import { navSectionClasses, navSectionCssVars, NavSectionHorizontal, NavUl } from 'src/components/nav-section';

// ----------------------------------------------------------------------

export default function HeaderCodelab() {
  const theme = useTheme();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const currentProjectId = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.id)

  const currentMachineLearningProject = useSelector((state: RootState) => state.machineLearningProjects.currentProject?.project)
  const mlTrainingStatus = useSelector((state: RootState) => state.machineLearningProjects.currentProject?.trainingStatus ?? 'not_started')

  const pathname = usePathname();

  const isSmallScreen = useIsSmallScreen()

  const router = useRouter()

  const cssVars = {
    ...navSectionCssVars.horizontal(theme),
    '--nav-item-gap': '24px',
  };

  return (
    <AppBar>

      <Toolbar
        sx={{
          // alignItems: 'center',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >

        <Scrollbar
          sx={{ height: 1 }}
          slotProps={{
            content: { height: 1 },
          }}
        >
          <Stack
            component="nav"
            direction="row"
            alignItems="center"
            className={navSectionClasses.horizontal.root}
            sx={{
              ...cssVars,
              mx: 'auto',
              height: 1,
              minHeight: 'var(--nav-height)',
            }}
          >
            {
              // codelab
              currentProjectId && (pathname === paths.codelab.project(currentProjectId)) &&
              <>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <Box mr={2}>
                    <Logo disableLink logoType={isSmallScreen ? 'original' : 'codelab'} />
                  </Box>
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <NavBarButton onClick={() => router.push(paths.home)} text={"Home"} icon={'home'} />
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <BrowseProjectsListButton />
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <CreateNewProjectButton />
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <SaveCurrentProjectButton />
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <CopyBlocklyProjectButton buttonType={CopyBlocklyProjectButtonType.NAVBAR} blocklyProjectId={currentProjectId} ></CopyBlocklyProjectButton>
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <GenerateQRCodeButton />
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <div className="vr"></div>
                </NavUl>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <ProjectNameEditorButton />
                </NavUl>
              </>
            }

            {
              // Machine learning
              pathname.startsWith(paths.codelab.machineLearning.projectPath) && currentMachineLearningProject &&
              <>
                <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}>
                  <Button
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                    onClick={() => { router.back(paths.codelab.machineLearning.allProjects) }}
                  >
                    <Typography variant="subtitle2">BACK</Typography>
                  </Button>
                </NavUl>

                {/* Disallow Editing when training is still in progress */}
                {mlTrainingStatus !== 'training_in_progress' && <NavUl sx={{ flexDirection: 'row', gap: 'var(--nav-item-gap)' }}><MachineLearningEditProjectButton projectToUpdate={currentMachineLearningProject} /></NavUl>}
              </>
            }
          </Stack>
        </Scrollbar>

        <HeaderEndStack />
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar >
  );
}
