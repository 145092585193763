import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import CompactLayout from 'src/layouts/compact';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import('src/pages/auth/amplify/login'));
const AmplifyRegisterPage = lazy(() => import('src/pages/auth/amplify/register'));
const AmplifyInvitedRegistrationPage = lazy(() => import('src/pages/auth/amplify/invitedRegistration'));
const AmplifyVerifyPage = lazy(() => import('src/pages/auth/amplify/verify'));
const AmplifyNewPasswordPage = lazy(() => import('src/pages/auth/amplify/new-password'));
const AmplifyForgotPasswordPage = lazy(() => import('src/pages/auth/amplify/forgot-password'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard redirectForAuthenticated>
            <AuthClassicLayout title="Hi, Welcome back">
              <AmplifyLoginPage />
            </AuthClassicLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <GuestGuard redirectForAuthenticated>
            <AuthClassicLayout >
              <AmplifyRegisterPage />
            </AuthClassicLayout>
          </GuestGuard>
        ),
      },
      {
        path: 'invited_registration',
        element: (
          <GuestGuard redirectForAuthenticated={false}>
            <AuthClassicLayout >
              <AmplifyInvitedRegistrationPage />
            </AuthClassicLayout>
          </GuestGuard>
        ),
      },
      {
        element: (
          <CompactLayout>
            <Outlet />
          </CompactLayout>
        ),
        children: [
          { path: 'verify', element: <AmplifyVerifyPage /> },
          { path: 'new-password', element: <AmplifyNewPasswordPage /> },
          { path: 'forgot-password', element: <AmplifyForgotPasswordPage /> },
        ],
      },
    ],
  },
];
