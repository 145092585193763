import { createAsyncThunk } from '@reduxjs/toolkit'
import { OwnerType, MachineLearningModelType, MachineLearningProject, DeleteMachineLearningProjectMutationVariables, DeleteMachineLearningProjectMutation } from "../../API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { RootState, AppDispatch } from '../store'
import { graphQLClient } from '../util'

export const deleteMachineLearningProject = createAsyncThunk<
    DeleteMachineLearningProjectMutationVariables,
    DeleteMachineLearningProjectMutationVariables, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('machineLearningProjects/deleteMachineLearningProject', async (input: DeleteMachineLearningProjectMutationVariables, { dispatch, getState }) => {
    const { id } = input
    const variables: DeleteMachineLearningProjectMutationVariables = {
        id
    }
    const response: GraphQLResult<DeleteMachineLearningProjectMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteMachineLearningProjectMutation>>({
        query: mutations.deleteMachineLearningProject,
        variables
    });

    if (response.data.deleteMachineLearningProject) {
        // success
        return input
    }
    throw new Error(`Failed to delete project ${id}`)
})