export interface LoadingSpinnerProps {
    spinnerSize?: number, // 1 / 2 / 3 etc.
    text?: string,
    textSize?: string,
    paddingBetween?: string
}

const LoadingSpinner = ({ spinnerSize = 3, text = 'Loading...', textSize = '1rem', paddingBetween = '5px' }: LoadingSpinnerProps) => {
    return (
        <div>
            <div className="d-flex justify-content-center align-items-center">
                <i className={`fa-solid fa-spinner fa-spin-pulse fa-${spinnerSize}x`}></i>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{ textAlign: 'center', fontSize: textSize, paddingTop: paddingBetween }}>
                {text}
            </div>
        </div>
    )
}

export default LoadingSpinner