import Box from '@mui/material/Box';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { SettingsState, useSettingsContext } from 'src/components/settings';
import { bulletColor } from 'src/components/nav-section';
import Alert from '@mui/material/Alert';
import { iconButtonClasses } from '@mui/material/IconButton';

import Header from './header';
import NavMini from './nav-mini';
import { NavVertical } from './nav-vertical';
import NavHorizontal from './nav-horizontal';
import { NavHorizontalV2 } from './nav-horizontal-v2';
import { layoutClasses } from '../classes';
import { MainV2 } from './mainV2';
import { useAccountDrawerData, useNavData } from './config-navigation';
import { Breakpoint, CSSObject, SxProps, Theme, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { varAlpha, stylesMode } from 'src/theme/styles';
import { NavMobile } from './nav-mobile';
import { LayoutSection } from '../core/layout-section';
import { HeaderBase } from 'src/layouts/core/header-base';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { OrganizationsPopoverPropsData } from '../components/organizations-selection-popover';
import { OrganizationDataForUser } from 'src/API';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
};

export default function MainLayout({ sx, children }: Props) {
  // const settings = useSettingsContext();

  // const lgUp = useResponsive('up', 'lg');

  // const layoutQuery: Breakpoint = 'lg'

  // const nav = useBoolean();

  // const theme = useTheme();

  // const isHorizontal = settings.navLayout === 'horizontal';

  // const isMini = settings.navLayout === 'mini';

  // const navData = useNavData();

  // const renderNavMini = <NavMini />;

  // const navColorVars = useNavColorVars(theme, settings);

  // const renderHorizontal = <NavHorizontal />;

  // const renderNavVertical = <NavVertical
  //   data={navData}
  //   layoutQuery={layoutQuery}
  //   isNavMini={false}
  //   onToggleNav={() =>
  //     settings.onUpdateField(
  //       'navLayout',
  //       settings.navLayout === 'vertical' ? 'mini' : 'vertical'
  //     )
  //   } />;

  // if (isHorizontal) {
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />

  //       {lgUp ? renderHorizontal : renderNavVertical}

  //       <Main>{children}</Main>
  //     </>
  //   );
  // }

  // if (isMini) {
  //   return (
  //     <>
  //       <Header onOpenNav={nav.onTrue} />

  //       <Box
  //         sx={{
  //           minHeight: 1,
  //           display: 'flex',
  //           flexDirection: { xs: 'column', lg: 'row' },
  //         }}
  //       >
  //         {lgUp ? renderNavMini : renderNavVertical}

  //         <Main>{children}</Main>
  //       </Box>
  //     </>
  //   );
  // }

  // return (
  //   <>
  //     <Header onOpenNav={nav.onTrue} />

  //     <Box
  //       sx={{
  //         minHeight: 1,
  //         display: 'flex',
  //         flexDirection: { xs: 'column', lg: 'row' },
  //       }}
  //     >
  //       {renderNavVertical}

  //       <Main>{children}</Main>
  //     </Box>
  //   </>
  // );

  const theme = useTheme();

  const mobileNavOpen = useBoolean();

  const settings = useSettingsContext();

  const navColorVars = useNavColorVars(theme, settings);

  const layoutQuery: Breakpoint = 'lg';

  const navData = useNavData();

  const accountDrawerData = useAccountDrawerData()

  const isNavMini = settings.navLayout === 'mini';

  const isNavHorizontal = settings.navLayout === 'horizontal';

  const isNavVertical = isNavMini || settings.navLayout === 'vertical';

  const organizationsRaw: OrganizationDataForUser[] = useSelector((state: RootState) => state.authentication.loggedInUser!!.organizations)
  const organizations: OrganizationsPopoverPropsData[] = organizationsRaw.map(organization => ({
    id: organization.userOrg.organizationID,
    name: organization.orgName,
    organizationRole: organization.userOrg.role,
  }))

  const workspaces: OrganizationsPopoverPropsData[] = [
    ...organizations,
    ...(organizations.length === 0 ? [{
      id: 'noOrganization',
      name: 'Personal',
      organizationRole: undefined,
    }] : []),
  ].sort((a, b) => a.organizationRole === undefined ? 1 : (a.name.localeCompare(b.name)))

  return (
    <>
      <NavMobile
        data={navData}
        open={mobileNavOpen.value}
        onClose={mobileNavOpen.onFalse}
        cssVars={navColorVars.section}
      />

      <LayoutSection
        /** **************************************
         * Header
         *************************************** */
        headerSection={
          <HeaderBase
            layoutQuery={layoutQuery}
            disableElevation={isNavVertical}
            onOpenNav={mobileNavOpen.onTrue}
            data={{
              nav: navData,
              ... (accountDrawerData && { account: accountDrawerData }),
              workspaces,
            }}
            slotsDisplay={{
              signIn: false,
              purchase: false,
              helpLink: false,
            }}
            slots={{
              topArea: (
                <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                  This is an info Alert.
                </Alert>
              ),
              bottomArea: isNavHorizontal ? (
                <NavHorizontalV2
                  data={navData}
                  layoutQuery={layoutQuery}
                  cssVars={navColorVars.section}
                />
              ) : null,
            }}
            slotProps={{
              toolbar: {
                sx: {
                  [`& [data-slot="logo"]`]: {
                    display: 'none',
                  },
                  [`& [data-area="right"]`]: {
                    gap: { xs: 0, sm: 0.75 },
                  },
                  ...(isNavHorizontal && {
                    bgcolor: 'var(--layout-nav-bg)',
                    [`& .${iconButtonClasses.root}`]: {
                      color: 'var(--layout-nav-text-secondary-color)',
                    },
                    [theme.breakpoints.up(layoutQuery)]: {
                      height: 'var(--layout-nav-horizontal-height)',
                    },
                    [`& [data-slot="workspaces"]`]: {
                      color: 'var(--layout-nav-text-primary-color)',
                    },
                    [`& [data-slot="logo"]`]: {
                      display: 'none',
                      [theme.breakpoints.up(layoutQuery)]: { display: 'inline-flex' },
                    },
                    [`& [data-slot="divider"]`]: {
                      [theme.breakpoints.up(layoutQuery)]: {
                        display: 'flex',
                      },
                    },
                  }),
                },
              },
              container: {
                maxWidth: false,
                sx: {
                  ...(isNavVertical && { px: { [layoutQuery]: 5 } }),
                },
              },
            }}
          />
        }
        /** **************************************
         * Sidebar
         *************************************** */
        sidebarSection={
          isNavHorizontal ? null : (
            <NavVertical
              data={navData}
              isNavMini={isNavMini}
              layoutQuery={layoutQuery}
              cssVars={navColorVars.section}
              onToggleNav={() =>
                settings.onUpdateField(
                  'navLayout',
                  settings.navLayout === 'vertical' ? 'mini' : 'vertical'
                )
              }
            />
          )
        }
        /** **************************************
         * Footer
         *************************************** */
        footerSection={null}
        /** **************************************
         * Style
         *************************************** */
        cssVars={{
          ...navColorVars.layout,
          '--layout-transition-easing': 'linear',
          '--layout-transition-duration': '120ms',
          '--layout-nav-mini-width': '88px',
          '--layout-nav-vertical-width': '300px',
          '--layout-nav-horizontal-height': '64px',
          '--layout-dashboard-content-pt': theme.spacing(1),
          '--layout-dashboard-content-pb': theme.spacing(8),
          '--layout-dashboard-content-px': theme.spacing(5),
        }}
        sx={{
          [`& .${layoutClasses.hasSidebar}`]: {
            [theme.breakpoints.up(layoutQuery)]: {
              transition: theme.transitions.create(['padding-left'], {
                easing: 'var(--layout-transition-easing)',
                duration: 'var(--layout-transition-duration)',
              }),
              pl: isNavMini ? 'var(--layout-nav-mini-width)' : 'var(--layout-nav-vertical-width)',
            },
          },
          ...sx,
        }}
      >
        <MainV2 isNavHorizontal={isNavHorizontal}>{children}</MainV2>
      </LayoutSection>
    </>
  );
}

// ----------------------------------------------------------------------

function useNavColorVars(
  theme: Theme,
  settings: SettingsState
): Record<'layout' | 'section', CSSObject> {
  const {
    vars: { palette },
  } = theme;

  return useMemo(() => {
    switch (settings.navColor) {
      case 'integrate':
        return {
          layout: {
            '--layout-nav-bg': palette.background.default,
            '--layout-nav-horizontal-bg': varAlpha(palette.background.defaultChannel, 0.8),
            '--layout-nav-border-color': varAlpha(palette.grey['500Channel'], 0.12),
            '--layout-nav-text-primary-color': palette.text.primary,
            '--layout-nav-text-secondary-color': palette.text.secondary,
            '--layout-nav-text-disabled-color': palette.text.disabled,
            [stylesMode.dark]: {
              '--layout-nav-border-color': varAlpha(palette.grey['500Channel'], 0.08),
              '--layout-nav-horizontal-bg': varAlpha(palette.background.defaultChannel, 0.96),
            },
          },
          section: {},
        };
      case 'apparent':
        return {
          layout: {
            '--layout-nav-bg': palette.grey[900],
            '--layout-nav-horizontal-bg': varAlpha(palette.grey['900Channel'], 0.96),
            '--layout-nav-border-color': 'transparent',
            '--layout-nav-text-primary-color': palette.common.white,
            '--layout-nav-text-secondary-color': palette.grey[500],
            '--layout-nav-text-disabled-color': palette.grey[600],
            [stylesMode.dark]: {
              '--layout-nav-bg': palette.grey[800],
              '--layout-nav-horizontal-bg': varAlpha(palette.grey['800Channel'], 0.8),
            },
          },
          section: {
            // caption
            '--nav-item-caption-color': palette.grey[600],
            // subheader
            '--nav-subheader-color': palette.grey[600],
            '--nav-subheader-hover-color': palette.common.white,
            // item
            '--nav-item-color': palette.grey[500],
            '--nav-item-root-active-color': palette.primary.light,
            '--nav-item-root-open-color': palette.common.white,
            // bullet
            '--nav-bullet-light-color': bulletColor.dark,
            // sub
            ...(settings.navLayout === 'vertical' && {
              '--nav-item-sub-active-color': palette.common.white,
              '--nav-item-sub-open-color': palette.common.white,
            }),
          },
        };
      default:
        throw new Error(`Invalid color: ${settings.navColor}`);
    }
  }, [
    palette.background.default,
    palette.background.defaultChannel,
    palette.common.white,
    palette.grey,
    palette.primary.light,
    palette.text.disabled,
    palette.text.primary,
    palette.text.secondary,
    settings.navColor,
    settings.navLayout,
  ]);
}