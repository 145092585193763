import React, { useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { CloseIcon } from 'yet-another-react-lightbox';
import { store, RootState } from '../../../store/store';
import { copyBlocklyProjectsByID } from '../../../store/blocklyProjects/blocklyProjectsSlice';
import NavBarButton from '../../../ui/navBar/NavBarButton';
import { AppConstants } from '../../../ui/Constants';

export enum CopyBlocklyProjectButtonType {
    NAVBAR,
    PROJECT_LIST_BOTTOM
}

interface CopyBlocklyProjectButtonProps {
    blocklyProjectId: string;
    buttonType: CopyBlocklyProjectButtonType;
}

export default function CopyBlocklyProjectButton({ buttonType, blocklyProjectId }: CopyBlocklyProjectButtonProps) {
    const blocklyProjectNameFromWorkspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.id === blocklyProjectId ? state.blocklyProjects.currentWorkspace?.projectName : null);
    const blocklyProjectNameFromProjectList = useSelector((state: RootState) => state.blocklyProjects.my_projects.find(p => p.id === blocklyProjectId)?.projectName);
    const blocklyProjectNameExisting = blocklyProjectNameFromWorkspace ?? blocklyProjectNameFromProjectList;
    const [modalShow, setModalShow] = useState(false);
    const [projectNameTyped, setProjectNameTyped] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectNameValidated, setProjectNameValidated] = useState(false);
    const [isDeleteButtonProcessing, setIsDeleteButtonProcessing] = useState(false);

    const onHide = () => setModalShow(false);
    const onShow = () => setModalShow(true);

    const onClick = () => {
        setProjectNameTyped(false);
        setIsDeleteButtonProcessing(false);
        setProjectNameValidated(true);
        setProjectName(blocklyProjectNameExisting ?? '');
        onShow();
    };

    const handleProjectNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setProjectNameTyped(true);
        setProjectName(value);
        setProjectNameValidated(value.length <= AppConstants.maxProjectNameCharacters && value.trim().length > 0);
    };

    const handleConfirm = () => {
        setIsDeleteButtonProcessing(true);
        store.dispatch(copyBlocklyProjectsByID({
            projectIDToCopy: blocklyProjectId,
            projectName,
            groupID: undefined,
            onSuccess: () => {
                setIsDeleteButtonProcessing(false);
                onHide();
            },
            onFailure: () => {
                setIsDeleteButtonProcessing(false);
            }
        }));
    };

    const buttonText = 'Copy';
    const button = <NavBarButton onClick={onClick} text={buttonText} icon={"copy"} />;

    return (
        <>
            {button}
            <Dialog
                open={modalShow}
                onClose={(_, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        onHide();
                    }
                }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>
                        Copy an Existing Project
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onHide}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box mb={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Existing Project Name *
                        </Typography>
                        <TextField
                            disabled
                            variant="outlined"
                            fullWidth
                            value={blocklyProjectNameExisting ?? ''}
                        />
                    </Box>
                    <Box mb={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            New Project Name *
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            autoFocus
                            placeholder="Please enter a project name"
                            value={projectName}
                            onChange={handleProjectNameChange}
                            error={projectNameTyped && !projectNameValidated}
                            helperText={projectNameTyped && !projectNameValidated && `Project name cannot be empty or more than ${AppConstants.maxProjectNameCharacters} characters`}
                        />
                        <Typography variant="body2" color="textSecondary" align="right">
                            {projectName.length}/{AppConstants.maxProjectNameCharacters}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHide} color="inherit" variant="contained">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleConfirm}
                        disabled={!projectNameValidated || isDeleteButtonProcessing}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
