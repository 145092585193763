import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import HeaderCodelab from './header-codelab';
import Box from '@mui/material/Box';
import Main from '../main/main';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function CodeLabWorkspaceLayout({ children }: Props) {
  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minWidth: '100vw',
      }}
    >
      <HeaderCodelab />
      <Box
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          flexShrink: 0
        }}
      >

      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // pt: 10,
          height: {
            xs: `calc(100vh - ${HEADER.H_MOBILE}px)`,
            md: `calc(100vh - ${HEADER.H_DESKTOP}px)`,
          },
          // textAlign: 'center',
          px: { xs: 2, md: 0 },
          overflow: 'auto'
          // position: 'relative',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}