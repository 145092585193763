import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../store"

export const deleteRobotFAQs = createAsyncThunk<
    {
        organizationID: string,
        deletedRobotFAQs: string[],
    }, // output
    {
        questionsToDelete: string[],
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteRobotFAQs', async ({ questionsToDelete }, { dispatch, getState }) => {
    // TODO implement
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    return {
        organizationID,
        deletedRobotFAQs: questionsToDelete
    }
})