import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { RobotConciergeState } from './types';
import { fetchConversationHistory, fetchConversationHistoryKey } from './fetchConversationHistory';
import { OrganizationData, OrganizationDataForUser, OrganizationUser } from 'src/API';
import fetchRobotFAQs, { fetchRobotFAQsKey } from './fetchRobotFAQs';
import { deleteRobotFAQs } from './deleteRobotFAQs';
import getOrganizationRobots, { getOrganizationRobotsKey } from './getOrganizationRobots';
import { ReducerStatus } from '../util';

const initialState: RobotConciergeState = {
    perOrganizationStates: [],
    fetchStatus: {}
}

export const robotConciergeSlice = createSlice({
    name: 'robotConcierge',
    initialState,
    reducers: {
        updateFetchStatus: (state, action: PayloadAction<{ fetchKey: string, status: ReducerStatus }>) => {
            state.fetchStatus = { ...state.fetchStatus, [action.payload.fetchKey]: action.payload.status }
        },
    },
    extraReducers(builder) {
        // fetchConversationHistory
        builder.addCase(fetchConversationHistory.pending, (state, action) => {
        })
        builder.addCase(fetchConversationHistory.fulfilled, (state, action) => {
            const payload = action.payload
            // important - otherwise it will update state even if no value is changed
            if (payload === undefined) {
                state.fetchStatus[fetchConversationHistoryKey] = 'fulfilled'
                return
            }
            const stateToChange = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!.perRobotStates!!.find(s => s.robotDeviceID === payload.robotDeviceID)!!

            // remove existing
            const newDatesDataToAdd = payload.convoHistoriesToAdd.map(x => x.date)
            const convos = (stateToChange.conversations ?? []).filter(c => !newDatesDataToAdd.includes(c.date))
            // add new
            const afterAppend = [...convos, ...payload.convoHistoriesToAdd]
            // sort desc
            const final = afterAppend.sort((a, b) => b.date.localeCompare(a.date))
            stateToChange.conversations = final

            // emit succcess
            enqueueSuccessSnackbarMessage('Successfully fetched robot conversation history')
            state.fetchStatus[fetchConversationHistoryKey] = 'fulfilled'
        })
        builder.addCase(fetchConversationHistory.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch robot conversation history')
            state.fetchStatus[fetchConversationHistoryKey] = 'rejected'
        })

        // fetchRobotFAQs
        builder.addCase(fetchRobotFAQs.pending, (state, action) => {
        })
        builder.addCase(fetchRobotFAQs.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                state.fetchStatus[fetchRobotFAQsKey] = 'fulfilled'
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)
            if (existing) {
                existing.robotFAQs = payload.robotFAQs
            }
            else {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    robotFAQs: payload.robotFAQs
                })
            }
            state.fetchStatus[fetchRobotFAQsKey] = 'fulfilled'
        })
        builder.addCase(fetchRobotFAQs.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch robot FAQs')
            state.fetchStatus[fetchRobotFAQsKey] = 'rejected'
        })

        // deleteRobotFAQs
        builder.addCase(deleteRobotFAQs.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(deleteRobotFAQs.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.robotFAQs) {
                existing.robotFAQs = existing.robotFAQs!!.filter(x => !action.payload.deletedRobotFAQs.includes(x.question))
            }

            enqueueSuccessSnackbarMessage('Successfully deleted robot FAQ(s)')
        })
        builder.addCase(deleteRobotFAQs.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to delete robot FAQs')
        })

        // getOrganizationRobots
        builder.addCase(getOrganizationRobots.pending, (state, action) => {
        })
        builder.addCase(getOrganizationRobots.fulfilled, (state, action) => {
            const payload = action.payload
            if (!payload) {
                state.fetchStatus[getOrganizationRobotsKey] = 'fulfilled'
                return
            }
            state.perOrganizationStates = [
                ...state.perOrganizationStates,
                {
                    organizationID: payload.organizationID,
                    perRobotStates: payload.kebbiDevicesForOrganization.map(k => ({
                        robotDeviceID: k.id
                    }))
                }
            ];
            state.fetchStatus[getOrganizationRobotsKey] = 'fulfilled'
        })
        builder.addCase(getOrganizationRobots.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load robots')
            state.fetchStatus[getOrganizationRobotsKey] = 'rejected'
        })
    }
})

export const { updateFetchStatus } = robotConciergeSlice.actions

export default robotConciergeSlice.reducer