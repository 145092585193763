import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense } from 'react';
// import { BrowserRouter } from 'react-router-dom';
import App from './app'

import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { history } from 'src/routes/history'

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <HelmetProvider>
    {/* <BrowserRouter> */}
    <Provider store={store}>
      <ReduxRouter history={history}>
        <Suspense>
          {/* <Authenticator.Provider> */}
          {/* - this has issues. may reload screen twice initially */}
          {/* <React.StrictMode> */}
          {/* <RouterProvider router={router}></RouterProvider> */}
          <App />
          {/* </React.StrictMode> */}
          {/* </Authenticator.Provider> */}
        </Suspense>
      </ReduxRouter>
    </Provider>
    {/* </BrowserRouter> */}
  </HelmetProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()