import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();

  const router = useMemo(
    () => ({
      back: (fallbackUrl: string) => {
        console.log('called back: ' + fallbackUrl)
        if (window.history.length > 2) {
          navigate(-1);
        } else {
          navigate(fallbackUrl);
        }
      },
      forward: () => navigate(1),
      reload: () => {
        console.log('called reload')
        window.location.reload() // navigate(0)
      },
      push: (href: string) => {
        console.log('called push: ' + href)
        navigate(href)
      },
      replace: (href: string) => {
        console.log('called replace: ' + href)
        navigate(href, { replace: true })
      },
    }),
    [navigate]
  );

  return router;
}
