/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "createStudentByFromInvitedRegistration",
            "endpoint": "https://ylo04uei89.execute-api.ap-southeast-1.amazonaws.com/prodb",
            "region": "ap-southeast-1"
        },
        {
            "name": "getCredentialsFromToken",
            "endpoint": "https://zfccfknq15.execute-api.ap-southeast-1.amazonaws.com/prodb",
            "region": "ap-southeast-1"
        },
        {
            "name": "whatsapp",
            "endpoint": "https://oh1wtb5id3.execute-api.ap-southeast-1.amazonaws.com/prodb",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://tioy5gzjevevhlcpx3vx3bdo54.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3pcexgx2ezcrhnea3rrfhxmcde",
    "aws_cognito_identity_pool_id": "ap-southeast-1:c4febb34-6342-4d79-b589-f587c885988e",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_VXHPDfO39",
    "aws_user_pools_web_client_id": "3mka4h2ga28ttupeirkf5c2k9c",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lyzaeducationapp3cd8b65160ed4c9e957a1e2de7034bed7f9f-prodb",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
