import awsmobile from './aws-exports';
import { paths } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// export const AMPLIFY_API = {
//   userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
//   userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
//   region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
// };

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const SUPABASE_API = {
  url: process.env.REACT_APP_SUPABASE_URL,
  key: process.env.REACT_APP_SUPABASE_ANON_KEY,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.home;

// dev or prodb
export const ENV = awsmobile.aws_user_files_s3_bucket.match(/.*-(\w+)/)!![1]
export const getCustomAPIBaseURL = () => {
  const apiGatewayID = ENV === 'dev' ? '6vbemdncch' : ''
  return `https://${apiGatewayID}.execute-api.${awsmobile.aws_project_region}.amazonaws.com/dev/machine-learning`
}
