import { Navigate, useRoutes } from 'react-router-dom';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { authRoutes } from './sections/auth';
import { mainRoutesWithoutAuth } from './sections/main';
import { robotManagementRoutes } from './sections/robot-management';
import { codelabRoutes } from './sections/codelab';
import MainLayout from 'src/layouts/main';
import { AuthGuard } from 'src/auth/guard'
import { lazy } from 'react';
import { managementRoutes } from './sections/management';

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home'));

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout>
            <HomePage />
          </MainLayout>
        </AuthGuard>
      ),
    },

    // Auth routes
    ...authRoutes,

    // Codelab routes
    ...codelabRoutes,

    // Robot Management routes
    ...robotManagementRoutes,

    // Main routes without auth
    ...mainRoutesWithoutAuth,

    // Management routes
    ...managementRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
