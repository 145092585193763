import React, { useEffect, useRef, ChangeEvent } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from 'yet-another-react-lightbox';
import { createNewProject, updateProject, loadFileFromLocalOrS3 } from '../../../store/blocklyProjects/blocklyProjectsSlice';
import * as ProjectFiles from '../../../store/blocklyProjects/projectFiles';
import { store, RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { FileCategory } from '../../../API';
import * as S3Util from '../../../fileStorage/s3Util';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import { AppConstants } from '../../../ui/Constants';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { DownloadStatus } from 'src/fileStorage/fileManager';

export interface CreateOrEditProjectModalProps {
    isCreate: boolean,
    projectImage: File | undefined,
    setProjectImage: (image: File | undefined) => void,
    projectImageURL: string | undefined,
    setProjectImageURL: (url: string | undefined) => void,
    projectNameValidated: boolean,
    setProjectNameValidated: (validated: boolean) => void,
    projectName: string,
    setProjectName: (name: string) => void,
    projectNameTyped: boolean,
    setProjectNameTyped: (typed: boolean) => void,
    hasProjectImageChanged: boolean,
    setHasProjectImageChanged: (changed: boolean) => void
}

export interface CreateOrEditProjectModalOutput {
    modal: () => JSX.Element,
    showModal: () => void
}

const CreateOrEditProjectModal = ({ isCreate, projectImage, setProjectImage, projectImageURL, setProjectImageURL, projectNameValidated, setProjectNameValidated, projectName, setProjectName, projectNameTyped, setProjectNameTyped, hasProjectImageChanged, setHasProjectImageChanged }: CreateOrEditProjectModalProps): CreateOrEditProjectModalOutput => {
    const workspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace);
    const isEligibleForCodingLandCreator = useSelector((state: RootState) => state.authentication.loggedInUser!!.isEligibleForCodingLandCreator);

    const existingProjectImageName = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.files.find(f => f.category === FileCategory.PROJECT_PROFILE_IMAGE)?.name);
    const existingProjectImageDownloadStatus = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace?.files.find(f => f.category === FileCategory.PROJECT_PROFILE_IMAGE)?.downloadStatus);

    const [modalShow, setModalShow] = React.useState(false);
    const onHide = () => {
        setModalShow(false);
    };
    const showModal = () => {
        setModalShow(true);
    };

    useEffect(() => {
        if (modalShow) {
            if (!isCreate) {
                if (projectName.trim() === "") {
                    setProjectName(workspace?.projectName ?? "");
                }
                if (existingProjectImageName !== undefined) {
                    if (existingProjectImageDownloadStatus === DownloadStatus.NOT_STARTED) {
                        store.dispatch(loadFileFromLocalOrS3({ fileName: existingProjectImageName, category: FileCategory.PROJECT_PROFILE_IMAGE }));
                    } else if (existingProjectImageDownloadStatus === DownloadStatus.COMPLETED) {
                        const file = ProjectFiles.getFileFromProject(existingProjectImageName, FileCategory.PROJECT_PROFILE_IMAGE)!!;
                        setProjectImage(file.file);
                        setProjectImageURL(file.localUrl);
                    }
                } else {
                    setProjectImage(undefined);
                    setProjectImageURL(undefined);
                }
            }
            setProjectNameValidated(projectName.length <= AppConstants.maxProjectNameCharacters && projectName.trim().length > 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [existingProjectImageName, existingProjectImageDownloadStatus, isCreate, workspace, projectName, modalShow]);

    const formMethods = useForm({
        defaultValues: {
            projectName,
        },
        mode: 'onChange',
    });

    const { handleSubmit, setValue, watch, formState: { isValid } } = formMethods;
    const watchedProjectName = watch('projectName');

    useEffect(() => {
        setValue('projectName', projectName);
    }, [projectName, setValue]);

    const handleProjectNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setProjectNameTyped(true);
        setProjectName(value);
        setValue('projectName', value, { shouldValidate: true });
    };

    const handleSubmitForm = async (data: { projectName: string }) => {
        if (isValid) {
            if (isCreate) {
                store.dispatch(createNewProject({
                    projectName: data.projectName,
                    projectImage
                }));
                setProjectImage(undefined);
                setProjectImageURL(undefined);
                setProjectNameValidated(false);
                setProjectName("");
                setProjectNameTyped(false);
                setHasProjectImageChanged(false);
            } else {
                store.dispatch(updateProject({ projectName: data.projectName, projectImage: hasProjectImageChanged ? projectImage : undefined }));
            }
            setModalShow(false);
        }
    };

    const CodingLandCardUploadField = () => {
        const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                setProjectImage(file);
                setHasProjectImageChanged(true);
                setProjectImageURL(URL.createObjectURL(file));
            }
        };
        const fileInputRef = useRef<HTMLInputElement>(null);
        return (
            <>
                {isEligibleForCodingLandCreator &&
                    <>
                        <Box mb={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Card Image
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
                                    Upload
                                </Button>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept={Array.from(S3Util.IMAGE_EXTENSIONS).join(',')} onChange={handleImageChange} />
                                <Typography variant="body2" marginLeft={2}>
                                    {projectImage?.name ?? 'No file chosen'}
                                </Typography>
                            </Box>
                        </Box>
                        {projectImage && projectImageURL &&
                            <Box display="flex" justifyContent="center" mb={2}>
                                <img
                                    src={projectImageURL}
                                    alt="Selected"
                                    style={{ width: "30%", height: "auto" }}
                                />
                            </Box>}
                        {projectImage && !projectImageURL && <LoadingSpinner text="Downloading card image..." />}
                    </>}
            </>
        );
    };

    const modal = () => (
        <Dialog open={modalShow}
            maxWidth="md"
            fullWidth
            onClose={(_, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onHide();
                }
            }}
        >
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(handleSubmitForm)} noValidate>
                    <DialogTitle sx={{ pb: 2, textAlign: 'center' }}>
                        <Typography variant="h5" gutterBottom>
                            {isCreate ? "Create New Project" : "Edit Project"}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            onClick={onHide}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box mb={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Project Name *
                            </Typography>
                            <Controller
                                name="projectName"
                                control={formMethods.control}
                                rules={{
                                    required: true,
                                    validate: value => value.trim().length > 0 && value.length <= AppConstants.maxProjectNameCharacters,
                                }}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        autoFocus
                                        placeholder="Please enter a project name"
                                        error={fieldState.invalid}
                                        helperText={fieldState.invalid && `Project name cannot be empty or more than ${AppConstants.maxProjectNameCharacters} characters`}
                                        onChange={handleProjectNameChange}
                                    />
                                )}
                            />
                            <Typography variant="body2" color="textSecondary" align="right">
                                {watchedProjectName.length}/{AppConstants.maxProjectNameCharacters}
                            </Typography>
                        </Box>

                        {CodingLandCardUploadField()}

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onHide} color="inherit" variant="contained">Cancel</Button>
                        <Button type="submit" color="primary" variant="contained" disabled={!isValid}>Save</Button>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );

    return {
        modal,
        showModal
    };
};

export default CreateOrEditProjectModal;
