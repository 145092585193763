import { LabelColor } from 'src/components/label';
import { info, error, primary, success, warning, secondary } from 'src/theme/core/palette';

// ----------------------------------------------------------------------

export const CALENDAR_COLOR_OPTIONS_MAP = {
  'default': info.dark,
  'error': error.main,
  'info': info.main,
  'primary': primary.main,
  'secondary': secondary.main,
  'success': success.main,
  'warning': warning.main,
}

export const CALENDAR_COLOR_OPTIONS = Object.values(CALENDAR_COLOR_OPTIONS_MAP)