import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store'
import { downloadData } from 'aws-amplify/storage'
import { graphQLClient } from 'src/store/util';
import { GraphQLQuery } from '@aws-amplify/api';
import { KebbiMaterialType, KebbiMaterials, ListKebbiMaterialsQuery, ListKebbiMaterialsQueryVariables, ModelSortDirection } from '../../API'
import * as queries from '../../graphql/queries'

interface KebbiMaterialsSliceState {
    motions: {
        [key: string]: KebbiMaterialState;
    } // title -> mapped material
    downloadMotionMetadataStatus: DownloadStatus
}

enum DownloadStatus {
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED'
}

const initialState: KebbiMaterialsSliceState = {
    downloadMotionMetadataStatus: DownloadStatus.NOT_STARTED,
    motions: {}
}

export type KebbiMaterialState = {
    data: KebbiMaterialMapped,
    isDownloadingResourceFile: boolean,
    downloadedLocalObjectURL?: string,
}

export type KebbiMaterialMapped = {
    title: string,
    identityID: string,
    kebbiMaterialType: KebbiMaterialType,
    fileName: string,
    i18n: {
        en_US: string
    }
}

function getKebbiMaterialKey(kebbiMaterialType: KebbiMaterialType, fileName: string) {
    return `${kebbiMaterialType}/${fileName}`
}

const STORAGE_ACCESS_LEVEL = 'protected'

async function downloadFromLocalOrS3(kebbiMaterialMapped: KebbiMaterialMapped): Promise<File | undefined> {
    try {
        const fileName = kebbiMaterialMapped.fileName
        const download = await downloadData({
            path: `${STORAGE_ACCESS_LEVEL}/${kebbiMaterialMapped.identityID}/${getKebbiMaterialKey(kebbiMaterialMapped.kebbiMaterialType, fileName)}`
        })
        const result = await download.result
        const blob = await result.body.blob()
        if (!blob) {
            // TODO log error
            throw Error("Failed to download file " + fileName)
        }
        // Create a File from the Blob
        return new File([blob], fileName, { type: blob.type });
    } catch (e) {
        // TODO log error
        console.error(e)
        return undefined
    }
}

export const startLoadKebbiMaterialResourceFilesIfNotDownloaded = createAsyncThunk<
    void,
    {
        kebbiMaterialMapped: KebbiMaterialMapped
    }, // input
    {
        state: RootState,
        dispatch: AppDispatch
    }
>('kebbiMaterials/startLoadKebbiMaterialResourceFilesIfNotDownloaded', async ({ kebbiMaterialMapped }, { getState, dispatch }) => {
    // load motions metadata if not already loaded
    switch (kebbiMaterialMapped.kebbiMaterialType) {
        case KebbiMaterialType.MOTION:
            const existing = getState().kebbiMaterials.motions[kebbiMaterialMapped.title] ?? {
                data: kebbiMaterialMapped,
                isDownloadingResourceFile: false
            }
            if (existing.isDownloadingResourceFile || existing.downloadedLocalObjectURL !== undefined) {
                return;
            }

            dispatch(updateMotionMaterial({
                data: existing.data,
                isDownloadingResourceFile: true
            }))
            const file = await downloadFromLocalOrS3(kebbiMaterialMapped)
            const downloadedLocalObjectURL = file ? URL.createObjectURL(file) : ''
            dispatch(updateMotionMaterial({
                data: {
                    ...existing.data
                },
                downloadedLocalObjectURL,
                isDownloadingResourceFile: false
            }))
            break;

        default:
            // This should never be reached if all cases are covered
            const exhaustiveCheck: never = kebbiMaterialMapped.kebbiMaterialType;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
})

export const startLoadKebbiMaterialsByType = createAsyncThunk<
    void,
    { kebbiMaterialType: KebbiMaterialType }, // input
    {
        state: RootState,
        dispatch: AppDispatch
    }
>('kebbiMaterials/startLoadKebbiMaterialsByType', async ({ kebbiMaterialType }, { getState, dispatch }) => {
    switch (kebbiMaterialType) {
        case KebbiMaterialType.MOTION:
            if (getState().kebbiMaterials.downloadMotionMetadataStatus === DownloadStatus.IN_PROGRESS ||
                getState().kebbiMaterials.downloadMotionMetadataStatus === DownloadStatus.COMPLETED ||
                getState().kebbiMaterials.downloadMotionMetadataStatus === DownloadStatus.FAILED) {
                return
            }
            dispatch(updateMotionMetadataDownloadingStatus(DownloadStatus.IN_PROGRESS))

            const variables: ListKebbiMaterialsQueryVariables = {
                materialType: kebbiMaterialType,
                sortDirection: ModelSortDirection.ASC
            };
            await graphQLClient.graphql<GraphQLQuery<ListKebbiMaterialsQuery>>({
                query: queries.listKebbiMaterials,
                variables
            }).then(r => r.data!!.listKebbiMaterials!!.items!!.flatMap(i => i!!.materials).forEach(m => {
                const kebbiMaterialMapped = {
                    title: m.title,
                    identityID: m.identityID,
                    kebbiMaterialType,
                    fileName: m.fileName,
                    i18n: {
                        en_US: m.i18n.en_US
                    }
                } as KebbiMaterialMapped

                // download resource files
                dispatch(startLoadKebbiMaterialResourceFilesIfNotDownloaded({ kebbiMaterialMapped }))
            }))
            dispatch(updateMotionMetadataDownloadingStatus(DownloadStatus.COMPLETED))
            break;

        default:
            // This should never be reached if all cases are covered
            const exhaustiveCheck: never = kebbiMaterialType;
            throw new Error(`Unhandled case: ${exhaustiveCheck}`);
    }
})

export const kebbiMaterialsSlice = createSlice({
    name: 'kebbiMaterials',
    initialState,
    reducers: {
        updateMotionMetadataDownloadingStatus: (state, action: PayloadAction<DownloadStatus>) => {
            state.downloadMotionMetadataStatus = action.payload
        },
        updateMotionMaterial: (state, action: PayloadAction<KebbiMaterialState>) => {
            state.motions[action.payload.data.title] = action.payload
        }
    },
    extraReducers(builder) {
        // startLoadKebbiMaterialsByType
        builder.addCase(startLoadKebbiMaterialsByType.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(startLoadKebbiMaterialsByType.fulfilled, (state, action) => {
        })
        builder.addCase(startLoadKebbiMaterialsByType.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })

        // startLoadKebbiMaterialResourceFilesIfNotDownloaded
        builder.addCase(startLoadKebbiMaterialResourceFilesIfNotDownloaded.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            // mark as downloaded
        })
        builder.addCase(startLoadKebbiMaterialResourceFilesIfNotDownloaded.fulfilled, (state, action) => {

        })
        builder.addCase(startLoadKebbiMaterialResourceFilesIfNotDownloaded.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
    }
})

export const { updateMotionMetadataDownloadingStatus, updateMotionMaterial } = kebbiMaterialsSlice.actions

export default kebbiMaterialsSlice.reducer