export interface SqsEventPayloadInterface { }

export interface SqsScratchAccountEventPayload extends SqsEventPayloadInterface {
    lyzaUsername: string;
    orgId: string;
}

export enum SQSEventType {
    SCRATCH = "SCRATCH"
}

export interface SqsEvent<SqsEventPayloadInterface> {
    type: SQSEventType
    payload: SqsEventPayloadInterface
}

export enum CodingLanguage {
    // For available modes see https://github.com/thlorenz/brace/tree/master/mode
    PYTHON = "python"
}

export type CodespaceNotebookCell = {
    uuid: string;
    language: CodingLanguage,
    code: string,
    stdOut: string,
    stdErr: string,
    cellExecutedOrder: number | null, // if there's multiple cells been executed (in sequence, and first one always start from 1), this attribute marks the order that the cell is executed.
    isOutputExpanded: boolean, // by default yes. otherwise output is collapsed
    isEditorExpanded: boolean, // by default yes. otherwise editor is collapsed
}

export type CodespaceNotebook = {
    cells: CodespaceNotebookCell[]
}

type DatabaseEnv = {
    blocklyProject: {
        dev: string,
        prodb: string
    },
    user: {
        dev: string,
        prodb: string
    },
    organizationUser: {
        dev: string,
        prodb: string,
    }
    organizationData: {
        dev: string,
        prodb: string,
    },
    robotFAQ: {
        dev: string,
        prodb: string
    },
    conversationHistory: {
        dev: string,
        prodb: string
    },
    curriculum: {
        dev: string,
        prodb: string,
    }
    machineLearningProject: {
        dev: string,
        prodb: string
    },
    centre: {
        dev: string,
        prodb: string
    },
    classroom: {
        dev: string,
        prodb: string
    },
    class: {
        dev: string,
        prodb: string
    },
    classStudentRecord: {
        dev: string,
        prodb: string
    },
    classTeacherRecord: {
        dev: string,
        prodb: string
    },
    codespaceProject: {
        dev: string,
        prodb: string
    },
    kebbiDevice: {
        dev: string,
        prodb: string
    },
    tempAuthToken: {
        dev: string,
        prodb: string
    },
    userWhatsAppMessage: {
        dev: string,
        prodb: string
    },
    scratchAccount: {
        dev: string,
        prodb: string
    }
}

export const cognitoEnv = {
    dev: {
        userPoolID: 'ap-southeast-1_8HiiPrqD0',
        identityPoolID: 'ap-southeast-1:36bcd5f6-dafa-40f4-a98b-0d0c84564ee9',
        clientID: '71otdlftbsetc1ghrnla6tkabr'
    },
    prodb: {
        userPoolID: 'ap-southeast-1_VXHPDfO39',
        identityPoolID: 'ap-southeast-1:c4febb34-6342-4d79-b589-f587c885988e',
        clientID: '3mka4h2ga28ttupeirkf5c2k9c',
    }
}

export const DATABASE_ENV: DatabaseEnv = {
    blocklyProject: {
        dev: "BlocklyProject-gvwa5rr2nnc6xgbucpaoog5sdq-dev",
        prodb: "BlocklyProject-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    },
    user: {
        dev: "User-gvwa5rr2nnc6xgbucpaoog5sdq-dev",
        prodb: "User-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    },
    organizationUser: {
        dev: "OrganizationUser-gvwa5rr2nnc6xgbucpaoog5sdq-dev",
        prodb: "OrganizationUser-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    },
    organizationData: {
        dev: "OrganizationData-gvwa5rr2nnc6xgbucpaoog5sdq-dev",
        prodb: "OrganizationData-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    },
    robotFAQ: {
        dev: 'RobotFAQ-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'RobotCustomAction-otw6qx33mveqbhjaxv7mosfv5y-prodb',
    },
    conversationHistory: {
        dev: 'ConversationHistory-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'ConversationHistory-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    curriculum: {
        dev: 'Curriculum-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'Curriculum-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    machineLearningProject: {
        dev: 'MachineLearningProject-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'MachineLearningProject-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    centre: {
        dev: 'Centre-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'Centre-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    classroom: {
        dev: 'Classroom-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'Classroom-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    class: {
        dev: 'ClassObject-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'ClassObject-otw6qx33mveqbhjaxv7mosfv5y-prodb',
    },
    classStudentRecord: {
        dev: 'ClassStudentRecord-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'ClassStudentRecord-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    classTeacherRecord: {
        dev: 'ClassTeacherRecord-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'ClassTeacherRecord-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    codespaceProject: {
        dev: 'CodespaceProject-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'CodespaceProject-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    kebbiDevice: {
        dev: 'KebbiDevice-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'KebbiDevice-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    tempAuthToken: {
        dev: 'TempAuthToken-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: 'TempAuthToken-otw6qx33mveqbhjaxv7mosfv5y-prodb'
    },
    userWhatsAppMessage: {
        dev: 'UserWhatsAppMessage-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: "UserWhatsAppMessage-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    },
    scratchAccount: {
        dev: 'ScratchAccount-gvwa5rr2nnc6xgbucpaoog5sdq-dev',
        prodb: "ScratchAccount-otw6qx33mveqbhjaxv7mosfv5y-prodb"
    }
}

type MachineLearningEnv = {
    tfjsToTfliteConversion: {
        dev: string,
        prodb: string
    },
    tfjsModelTrainingLambda: {
        dev: string,
        prodb: string
    }
}

export type SeleniumLambdaFunctionInput = {
    scratch_account_creation?: {
        username: string,
        password: string,
        birth_year: number,
        birth_month: number,
    }
}

export const SELENIUM_ENV = {
    scratch_account_creation: {
        event_type: "scratch_account_creation",
        lambda_func_name: {
            dev: "selenium-dev",
            prodb: "selenium-prodb"
        }
    }
}

export const MACHINE_LEARNING_ENV: MachineLearningEnv = {
    tfjsToTfliteConversion: {
        dev: 'machine-learning-tfjs-to-tflite-converter-dev',
        prodb: 'machine-learning-tfjs-to-tflite-converter-prodb'
    },
    tfjsModelTrainingLambda: {
        dev: 'tfjs-model-training-dev',
        prodb: 'tfjs-model-training-prodb',
    }
}

type Env = 'dev' | 'prodb'

export const ENV: Env = process.env.ENV as Env
export const REGION: string = process.env.REGION ?? 'ap-southeast-1'
export const LyzaEducationAppUserGroupBasic = 'LyzaEducationAppUserGroupBasic'
export const ADMIN_GROUP = "admin"

export const SQS_QUEUE_URL = {
    scratch: {
        prodb: 'https://sqs.ap-southeast-1.amazonaws.com/462156249356/ScratchAccountManagementQueue-prodb.fifo',
        dev: 'https://sqs.ap-southeast-1.amazonaws.com/462156249356/ScratchAccountManagementQueue-dev.fifo',
    }
};

export const s3Bucket = {
    dev: "lyzaeducationapp3cd8b65160ed4c9e957a1e2de7034be7cc76-dev",
    prodb: "lyzaeducationapp3cd8b65160ed4c9e957a1e2de7034bed7f9f-prodb"
}

export const AUTH_TOKEN_QUERY_PARAM = 'authTokenId'
export const AUTH_TOKEN_USER_NAME = 'authTokenUsername'

export const TEMP_POWER_ADMIN_USERS = [
    'lizhanglin@lyza-sg.com',
    'youyang.zhang@lyza-sg.com',
    'evan.lim@lyza-sg.com',
    'koksiong.cheng@lyza-sg.com'
]
export const SEARCH_PARAM_SESSION_INDEX = 'sessionIdx'