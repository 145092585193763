import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import NavBarButton from '../../../ui/navBar/NavBarButton'
import CreateOrEditProjectModal from './CreateOrEditProjectModal'
import { useEffect, useState } from 'react'
import { truncateText } from '../utils'

export default function ProjectNameEditorButton() {
    const workspace = useSelector((state: RootState) => state.blocklyProjects.currentWorkspace)
    const [projectImage, setProjectImage] = useState<File | undefined>(undefined)
    const [projectImageURL, setProjectImageURL] = useState<string | undefined>(undefined)

    const [projectNameValidated, setProjectNameValidated] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectNameTyped, setProjectNameTyped] = useState(false);
    useEffect(() => {
        setProjectName(workspace?.projectName ?? '')
    }, [workspace?.projectName])
    const [hasProjectImageChanged, setHasProjectImageChanged] = useState<boolean>(false)
    const { modal, showModal } = CreateOrEditProjectModal({
        isCreate: false,
        projectImage,
        setProjectImage: (image) => { setProjectImage(image) },
        projectImageURL,
        setProjectImageURL: (url) => { setProjectImageURL(url) },
        projectNameValidated,
        setProjectNameValidated: (projectName) => { setProjectNameValidated(projectName) },
        projectName,
        setProjectName: (projectName) => { setProjectName(projectName) },
        projectNameTyped,
        setProjectNameTyped: (projectNameTyped) => { setProjectNameTyped(projectNameTyped) },
        hasProjectImageChanged,
        setHasProjectImageChanged: (changed) => { setHasProjectImageChanged(changed) }
    })
    const editProjectOnClick = () => {
        showModal()
    }

    return (
        <>
            {workspace &&
                <>
                    <NavBarButton onClick={editProjectOnClick} icon='edit' text={`${truncateText(projectName, 40)}.project`} textVariantOverride='caption' />
                    {modal()}
                </>}
        </>
    );
}