import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const UnauthorizedPage = lazy(() => import('src/pages/pageUnauthorized'));

// ----------------------------------------------------------------------

export const mainRoutesWithoutAuth = [
  // {
  // element: (
  //   <CodelabWorkspaceLayout>
  //     <Suspense fallback={<SplashScreen />}>
  //       <Outlet />
  //     </Suspense>
  //   </CodelabWorkspaceLayout>
  // ),
  // children: [
  // { path: 'about-us', element: <AboutPage /> },
  // { path: 'contact-us', element: <ContactPage /> },
  // { path: 'faqs', element: <FaqsPage /> },
  // {
  //   path: 'product',
  //   children: [
  //     { element: <ProductListPage />, index: true },
  //     { path: 'list', element: <ProductListPage /> },
  //     { path: ':id', element: <ProductDetailsPage /> },
  //     { path: 'checkout', element: <ProductCheckoutPage /> },
  //   ],
  // },
  // {
  //   path: 'post',
  //   children: [
  //     { element: <PostListPage />, index: true },
  //     { path: 'list', element: <PostListPage /> },
  //     { path: ':title', element: <PostDetailsPage /> },
  //   ],
  // },
  // ],
  // },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
      // { path: 'pricing', element: <PricingPage /> },
      // { path: 'payment', element: <PaymentPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'unauthorized', element: <UnauthorizedPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];
