import { SnackbarKey, SnackbarMessage, closeSnackbar, enqueueSnackbar } from 'notistack';

export function enqueueSuccessSnackbarMessage(message: SnackbarMessage): SnackbarKey {
    return enqueueSnackbar(message, { variant: 'success' })
}

export function enqueueErrorSnackbarMessage(message: SnackbarMessage): SnackbarKey {
    return enqueueSnackbar(message, { variant: 'error', autoHideDuration: 6000 })
}

export function enqueueWarningSnackbarMessage(message: SnackbarMessage): SnackbarKey {
    return enqueueSnackbar(message, { variant: 'warning' })
}

export function enqueueInfoSnackbarMessage(message: SnackbarMessage): SnackbarKey {
    return enqueueSnackbar(message, { variant: 'info' })
}

export function enqueueDefaultSnackbarMessage(message: SnackbarMessage): SnackbarKey {
    return enqueueSnackbar(message, { variant: 'default' })
}

export function enqueueCloseSnackbar(snackbarKey: SnackbarKey) {
    return closeSnackbar(snackbarKey)
}